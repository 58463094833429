<template>
    <b-modal id="modal-password-reset-form" dialog-class="modal-password-reset-form" modal-footer="no"
             :hide-footer="true">
        <template #modal-header>
            <p class="title">{{ $t('site.ui.password_reset.title') }}</p>
            <p class="subtitle">{{ $t('site.ui.password_reset.subtitle') }}</p>
            <div class="close" @click="$bvModal.hide('modal-password-reset-form')">
                <i class="cli cli-times"></i>
            </div>
        </template>

        <b-alert variant="danger" :show="requestFinished && !success">
            {{ $t('site.ui.password_reset.error') }}
        </b-alert>
        <b-alert variant="success" :show="requestFinished && success">
            {{ $t('site.ui.password_reset.success') }}
        </b-alert>

        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(doPasswordReset)" v-if="!requestFinished && !success">
                <b-form-group>
                    <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                        <p class="input-error" v-for="error in errors">{{ error }}</p>
                        <b-form-input
                            type="password"
                            v-model="password"
                            :placeholder="$t('site.form.password_reset.password')"
                        ></b-form-input>
                    </ValidationProvider>
                </b-form-group>

                <b-form-group>
                    <ValidationProvider vid="confirmation" v-slot="{ errors }">
                        <p class="input-error" v-for="error in errors">{{ error }}</p>
                        <b-form-input
                            type="password"
                            v-model="passwordConfirmation"
                            :placeholder="$t('site.form.password_reset.password_confirm')"
                        ></b-form-input>
                    </ValidationProvider>
                </b-form-group>
                <div class="d-flex align-items-center justify-content-center">
                    <b-button variant="primary" type="submit">
                        {{ $t('site.form.password_reset.submit') }}
                    </b-button>
                </div>
            </form>
        </ValidationObserver>
    </b-modal>
</template>

<script>

import queryString from 'query-string';

export default {
    props: {
        apiEndpoint: String
    },
    data: () => ({
        password: null,
        passwordConfirmation: null,
        requestFinished: false,
        success: false,
        params: {}
    }),
    mounted() {
        this.params = queryString.parse(location.search);

        if (this.params.action && this.params.action === 'password_reset' && this.params.hash) {
            setTimeout(() => {
                this.$bvModal.show('modal-password-reset-form');
            }, 1000);
        }
    },
    methods: {
        doPasswordReset() {
            this.requestFinished = false;
            this.success = false;

            axios.post(this.apiEndpoint, {
                password: this.password
            }, {
                params: {
                    hash: this.params.hash
                }
            })
                .then(res => res.data)
                .then(res => {
                    this.requestFinished = true;
                    this.success = true;
                })
                .catch(err => {
                    this.requestFinished = true;
                    this.success = false;
                });
        }
    }
}
</script>