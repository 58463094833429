var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showModal,
          expression: "showModal",
        },
      ],
      class: {
        "modal-container": true,
        "modal-blur": _vm.background === "blurred",
        "modal-darker": _vm.background === "darker",
        "modal-top-center": _vm.position === "centerTop",
        "modal-top-left": _vm.position === "leftTop",
        "modal-top-right": _vm.position === "rightTop",
        "modal-bottom-center": _vm.position === "centerBottom",
        "modal-bottom-left": _vm.position === "leftBottom",
        "modal-bottom-right": _vm.position === "rightBottom",
        "modal-center-left": _vm.position === "leftCenter",
        "modal-center-right": _vm.position === "rightCenter",
      },
      style: `width: ${_vm.width}px; height: ${_vm.height}px;`,
    },
    [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              _vm.showModal = false
            },
          },
        },
        [
          _c("i", {
            staticClass: "cli cli-times",
            staticStyle: { "z-index": "100" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-container" }, [_vm._t("content")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }