<template>
  <div
    class="hero-image-slider position-relative"
    :class="{'has--content-below-pagination': applyContentBelowPagination}"
  >
    <b-carousel
      id="carousel-1"
      ref="slider"
      v-model="index"
      class="hero-slider"
      :interval="0"
      :no-hover-pause="true"
      controls
      indicators
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="image in images"
        :key="image"
      >
        <template #img>
          <b-img-lazy
            :src="image"
            block
            fluid
            class="w-100"
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide
        v-if="hasPromoLastSlide"
        key="promo-slide"
        class="promo-slide"
      >
        <slot name="promo_slide" />
        <template #img>
          <b-img-lazy
            :src="lastImage"
            block
            fluid
            class="w-100"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div v-if="controls">
      <div class="hero-image-slider__progress position-absolute w-100">
        <div
          class="hero-image-slider__progress-bar"
          :style="{ width: percents + '%' }"
        />
      </div>
      <button
        class="hero-image-slider__button is-stop position-absolute text-center text-uppercase"
        @click="mode === 'play' ? stop() : play()"
      >
        <i
          v-if="mode === 'play'"
          class="cli cli-break"
        />
        <i
          v-if="mode === 'stop'"
          class="cli cli-play-button"
        />
        <span class="hero-image-slider__button-label d-block">Player</span>
      </button>
    </div>
    <slot name="text" />
    <slot
      v-if="applyContentBelowPagination"
      name="below_pagination"
    />
    <!--    <div class="hero-image-slider__overlay" />-->
  </div>
</template>

<script>
export default {
    props: {
        images: Array,
        controls: Boolean,
        hasContentBelowPagination: Boolean,
        hasPromoLastSlide: {
          type: Boolean,
          default: false
        }
    },
    data: () => ({
        interval: null,
        mode: 'play',
        percents: 0,
      index: 0,
      pause: false
    }),
  computed: {
      lastImage(){
        return this.images[this.images.length - 1]
      },
      applyContentBelowPagination(){
        // don't show bottom content when last slide (newsletter slide)
        return this.hasContentBelowPagination && this.index !== this.images.length
      }
  },
    created() {
        this.interval = setInterval(this.progressBar, 10);
    },
    methods: {
        progressBar() {
            this.percents += 0.1;

            if (this.percents > 100 && !this.pause) {
                this.percents = 0;
                this.$refs.slider.next();
            }
        },
        onSlideStart(slide) {
            clearInterval(this.interval);
            this.percents = 0;
        },
        onSlideEnd(slide) {
            this.interval = setInterval(this.progressBar, 10);
            // stop at newsletter slide
            if(this.hasPromoLastSlide){
              this.pause = slide === this.images.length
            }
        },
        play() {
            this.interval = setInterval(this.progressBar, 10);
            this.mode = 'play';
        },
        stop() {
            clearInterval(this.interval);
            this.mode = 'stop';
        }
    }
}
</script>