var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "propertyItemSlider",
        staticClass: "property-item-slider__wrapper keen-slider",
        on: {
          mouseover: function ($event) {
            return _vm.setPause(true)
          },
          mouseout: function ($event) {
            return _vm.setPause(false)
          },
        },
      },
      [_vm._t("items")],
      2
    ),
    _vm._v(" "),
    _vm.propertyItemSlider
      ? _c(
          "ol",
          { staticClass: "carousel-indicators" },
          _vm._l(_vm.dotHelper, function (slide, idx) {
            return _c("li", {
              key: idx,
              class: { dot: true, active: _vm.current === idx },
              on: {
                click: function ($event) {
                  return _vm.propertyItemSlider.moveToSlideRelative(idx)
                },
              },
            })
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }