export const bookmarks = {
    state: {
        counter: window.bookmarks
    },
    mutations: {
        addToBookmarks(state) {
            state.counter++;
        },
        removeFromBookmarks(state) {
            state.counter--;
        }
    }
};