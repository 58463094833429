export const filter = {
    state: {
        currentFilter: {
            category: [],
            city: [],
            region: [],
            immoPriceMax: null,
            immoPriceMin: null
        }
    },
    getters: {
        getCurrentFilter: state => {
            return state.currentFilter
        }
    },
    mutations: {
        changeCurrentFilter(state, payload) {
            state.currentFilter = payload;
        },
        changeCategory(state, payload) {
            state.currentFilter.category = payload;
        },
        changeCity(state, payload) {
            state.currentFilter.city = payload;
        },
        changeRegion(state, payload) {
            state.currentFilter.region = payload;
        },
        changeImmoPriceMax(state, payload) {
            state.currentFilter.immoPriceMax = payload;
        },
        changeImmoPriceMin(state, payload) {
            state.currentFilter.immoPriceMin = payload;
        }
    }
};