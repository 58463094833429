<template>
  <div class="property-grid-wrapper">
    <div
      v-if="query"
      class="search-query"
    >
      <p
        v-if="totalCount > 1"
        class="search-query__current"
        v-html="$t('site.ui.property_grid.current_query_plural', { query: query, count: totalCount})"
      />
      <p
        v-else
        class="search-query__current"
        v-html="$t('site.ui.property_grid.current_query', { query: query})"
      />

      <p
        class="search-query__reset"
        @click="resetSearch()"
      >
        {{ $t('site.ui.property_grid.reset_query') }}
      </p>
    </div>

    <property-filter
      v-else
      :all-collapsed="filterCollapsed"
      :all-open="filterAllOpen"
      :api-endpoint="apiEndpoint"
      :current-filter="index.currentFilter"
      :prepared-conditions="index.preparedConditions"
      :per-page="controls.perPage"
      :page="controls.page"
      :total-count="results.totalCount"
      :all-regions="allRegions"
      :all-categories="allCategories"
      :is-secret-sales="isSecretSales"
      @change="onFilterChange"
      @submit="onFilterSubmit"
    />


    <div class="spacer-50" />

    <!-- START GRID -->
    <div
      id="gridContainer"
      ref="gridContainer"
      class="container"
    >
      <div class="property-grid-top">
        <div class="top-info">
          <div class="left">
<!--            <div class="obj-count">-->
<!--              {{ $t('site.ui.property_grid.total_items_found', {count: results.totalCount}) }}-->
<!--            </div>-->
            <div class="subtext">
              {{ $t('site.ui.property_grid.current_filter_settings') }}
            </div>
          </div>
          <div class="right">
            <div class="pages">
              {{
                $t('site.ui.property_grid.page_of', {
                  current: controls.page, total: results.totalPages
                })
              }}
            </div>
            <div class="subtext">
              {{ currentFilterAsString }}
            </div>
          </div>
        </div>
        <div class="filter">
          <b-dropdown
            id="dropdown-1"
            variant="dropdown"
            :text="sortTypes[controls.sortType]"
            right
          >
            <b-dropdown-item
              v-for="(label, type) in sortTypes"
              :key="type"
              @click="setSortType(type)"
            >
              {{ label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="grid-html">
        <transition-group name="fade">
          <div
            :is="getCompiledHtml(item)"
            v-for="(item, index) in results.gridHtml"
            :key="'compiled-html-' + index"
            style="animation-duration: 0.6s"
          />
        </transition-group>
      </div>

      <div
        v-if="hasNextPage"
        class="load-more-container d-flex justify-content-center py-md-5 py-3"
      >
        <b-button
          :variant="isSecretSales ? 'secondary' : 'primary'"
          :disabled="loading"
          @click="loadMore"
        >
          <span v-if="loading">{{ $t('site.ui.property_grid.loading') }}</span>
          <span v-else>{{ $t('site.ui.property_grid.load_more') }}</span>
        </b-button>
      </div>
    </div>
    <!-- END GRID  -->
  </div>
</template>

<script>
import Vue from 'vue';
import PropertyFilter from "./PropertyFilter";
import queryString from "query-string";
import {EventBus} from '../event_bus'

export default {
  components: {
    PropertyFilter
  },
  props: {
    apiEndpoint: String,
    gridHtml: String,
    preparedConditions: Array,
    currentFilter: Object,
    page: [Number, String],
    perPage: [Number, String],
    totalPages: [Number, String],
    totalCount: {
      type: Number | String,
      default: 0
    },
    showFilter: Boolean,
    allRegions: Array,
    allCategories: Array,
    searchQuery: String,
    sortType: String,
    isSecretSales: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    query: null,
    filterAllOpen: false,
    loading: false,
    index: {
      preparedConditions: [],
      currentFilter: {}
    },
    controls: {
      page: 0,
      perPage: 8,
      sortType: 'immoPrice_asc'
    },
    results: {
      totalPages: 0,
      totalCount: 0,
      gridHtml: []
    }
  }),
  computed: {
    hasNextPage() {
      return this.controls.page < parseInt(this.results.totalPages);
    },
    filterCollapsed() {
      return !!this.query;
    },
    compiledGridHtml() {
      return Vue.compile(
          '<div class="grid-render-wrapper">' + this.results.gridHtml + '</div>'
      );
    },
    currentFilterAsString() {
      let filters = [];
      let currentFilter = this.$store.state.filter.currentFilter;

      if (!currentFilter) {
        return this.$t('site.ui.property_grid.current_filter_none');
      }

      // property types / category
      let categoryCondition = this.getCondition('category');
      let categoryFilters = [];

      if (currentFilter.category) {
        for (let categoryId of currentFilter.category) {
          for (let object of categoryCondition.objects) {
            if (object.id == parseInt(categoryId)) {
              categoryFilters.push(object.name);
            }
          }
        }

        if (categoryFilters.length > 0) {
          filters.push(categoryFilters.join(', '));
        }
      }

      // region
      let regionCondition = this.getCondition('region');
      let regionFilters = [];

      if (currentFilter.region) {
        for (let object of regionCondition.objects) {
          if (object.id == parseInt(currentFilter.region)) {
            regionFilters.push(object.name);
          }
        }

        if (regionFilters.length > 0) {
          filters.push(regionFilters.join(', '));
        }
      }

      // city
      let cityCondition = this.getCondition('city');
      let cityFilters = [];

      if (currentFilter.city) {
        for (let cityId of currentFilter.city) {
          for (let object of cityCondition.objects) {
            if (object.id == parseInt(cityId)) {
              cityFilters.push(object.name);
            }
          }
        }

        if (cityFilters.length > 0) {
          filters.push(cityFilters.join(', '));
        }
      }

      // budget
      if (currentFilter.immoPriceMax) {
        filters.push(this.getBudgetLabel(currentFilter.immoPriceMin ? currentFilter.immoPriceMin : 0, currentFilter.immoPriceMax));
      }

      if (filters.length > 0) {
        return filters.join(' | ');
      }

      return this.$t('site.ui.property_grid.current_filter_none');
    },
    sortTypes() {
      return {
        popular: this.$t('site.ui.property_grid.sort_popular_first'),
        immoPrice_desc: this.$t('site.ui.property_grid.price_descending'),
        immoPrice_asc: this.$t('site.ui.property_grid.price_ascending'),
        creationDate_desc: this.$t('site.ui.property_grid.latest')
      };
    }
  },
  created() {
    this.query = this.searchQuery
    this.controls.page = parseInt(this.page);
    this.controls.perPage = parseInt(this.perPage);
    this.results.totalCount = this.totalCount;
    this.results.gridHtml = [this.gridHtml];
    this.index.preparedConditions = this.preparedConditions;
    this.index.currentFilter = this.currentFilter;
    this.results.totalPages = this.totalPages;
    this.controls.sortType = this.sortType
    this.filterAllOpen = !this.isCurrentFilterEmpty();

    if (this.filterAllOpen) {
      let that = this;

      setTimeout(() => {
        that.$nextTick(() => {
          this.$scrollTo('#gridContainer');
        });
      }, 1000);
    }
  },
  methods: {
    getCompiledHtml(gridHtml) {
      return Vue.compile(gridHtml);
    },
    resetSearch() {
      this.query = null;

      EventBus.$emit('property_search_reset')

      this.loadData()
    },
    getCondition(fieldName) {
      if (this.index.preparedConditions) {
        for (let condition of this.index.preparedConditions) {
          if (condition.fieldName === fieldName) {
            return condition;
          }
        }
      }

      return false;
    },
    onSearch() {
      this.loadData();
    },
    onFilterChange() {
      this.controls.page = 1; // go back to first page
      this.loadData();
    },
    onFilterSubmit(payload) {
      this.$nextTick(() => {
        this.$scrollTo('#gridContainer');
      })
    },
    getBudgetLabel(immoPriceMinCondition, immoPriceMaxCondition) {
      if (immoPriceMinCondition === 0 && immoPriceMaxCondition === 100000000) {
        return this.$t('site.ui.property_filter_option.step_1');
      } else if (immoPriceMinCondition === 100000000 && immoPriceMaxCondition === 500000000) {
        return this.$t('site.ui.property_filter_option.step_2');
      } else if (immoPriceMinCondition === 500000000 && immoPriceMaxCondition === 1390000000) {
        return this.$t('site.ui.property_filter_option.step_3');
      }

      return '';
    },
    loadData(appendData = false) {
      let data = {
        ...this.$store.state.filter.currentFilter,
        ...this.controls
      };


      if (this.query) {
        data.query = this.query;
      }


      this.persistToUrl(data);

      // add ajax flag
      data = {
        ...data,
        ...{
          ajax: true
        }
      }

      this.loading = true;

      axios.get(this.apiEndpoint, {
        params: data
      })
          .then(res => res.data)
          .then(res => {
            this.loading = false;
            this.results.totalCount = res.totalCount;
            this.index.currentFilter = res.currentFilter;
            this.results.totalPages = res.totalPages;

            if (appendData) {
              this.results.gridHtml.push(res.gridHtml);
            } else {
              this.results.gridHtml = [res.gridHtml];
            }


            this.updateCityCondition(res.conditions);
          })
          .catch(err => {
            this.loading = false;
            console.log('could not load data', err);
          })
    },
    setSortType(type) {
      this.controls.sortType = type;

      this.loadData();
    },
    loadMore() {
      this.controls.page++;
      this.loadData(true);
    },
    updateCityCondition(newConditions) {
      newConditions.forEach((condition, i) => {
        if (condition.fieldName === 'city') {
          Vue.set(this.index.preparedConditions, i, condition);
        }
      });
    },
    isCurrentFilterEmpty() {
      if (this.index.currentFilter) {
        if (this.index.currentFilter.category.length > 0) {
          return false;
        }

        if (this.index.currentFilter.region != null) {
          return false;
        }
      }

      return true;
    },
    persistToUrl(params) {
      window.history.pushState(params, document.title, this.getUrl(params))
    },
    getUrl(params, baseUrl = null) {
      let query = '?' + queryString.stringify(params, {
        arrayFormat: 'bracket'
      });

      if (baseUrl != null) {
        return baseUrl + query;
      }

      return location.protocol + '//' + location.host + location.pathname + query;
    },
  }
}
</script>

<style lang="scss">

@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.search-query {
  padding: 0 50px;
  margin-top: 50px;

  .search-query__current {
    font-size: 26px;
    text-align: center;
    margin-bottom: 5px;

    strong {
      font-weight: bold;
      color: $navi;
    }
  }

  .search-query__reset {
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #888;
    margin-bottom: 15px;

    &:hover {
      color: $navi;
    }
  }
}
</style>
