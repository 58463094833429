var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-property-form" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "d-none", attrs: { for: "search" } }, [
        _vm._v(_vm._s(_vm.$t("cli.form.property_search.placeholder"))),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query",
          },
        ],
        staticClass: "form-control",
        attrs: {
          id: "search",
          type: "text",
          placeholder: _vm.$t("cli.form.property_search.placeholder"),
        },
        domProps: { value: _vm.query },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnter()
          },
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.query = $event.target.value
            },
            function ($event) {
              return _vm.onSearch()
            },
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "submit",
          on: {
            click: function ($event) {
              return _vm.$emit("enter")
            },
          },
        },
        [_c("i", { staticClass: "cli cli-search" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }