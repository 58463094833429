<template>
    <div class="animated-icon">
        <div ref="iconHolder" class="icon-holder" v-html="processedSvgCode"></div>
    </div>
</template>
<script>
export default {
    props: {
        svgCode: String,
        iconHolderId: String,
    },
    mounted() {
        setTimeout(() => {
            this.$refs.iconHolder.querySelector('svg').classList.add('active')
        }, 200);
    },
    beforeDestroy() {
        this.$refs.iconHolder.querySelector('svg').classList.remove('active')
    },
    computed: {
        processedSvgCode() {
            return this.svgCode.replaceAll('svg-elem', this.iconHolderId);
        }
    }
}
</script>