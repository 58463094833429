import Vue from 'vue';
import Vuex from 'vuex';
import {bookmarks} from './modules/bookmarks';
import {filter} from './modules/filter';
import {search} from "./modules/search";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        bookmarks,
        filter,
        search
    }
});