<template>
    <div
        v-show="showModal"
        :class="{
           'modal-container': true,
           'modal-blur': background === 'blurred',
           'modal-darker': background === 'darker',
           'modal-top-center': position === 'centerTop',
           'modal-top-left': position === 'leftTop',
           'modal-top-right': position === 'rightTop',
           'modal-bottom-center': position === 'centerBottom',
           'modal-bottom-left': position === 'leftBottom',
           'modal-bottom-right': position === 'rightBottom',
           'modal-center-left': position === 'leftCenter',
           'modal-center-right': position === 'rightCenter'
        }"
        :style="`width: ${width}px; height: ${height}px;`"
    >
        <button @click="showModal = false">
            <i class="cli cli-times" style="z-index: 100;"/>
        </button>
        <div class="content-container">
            <slot name="content"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {type: String},
        height: {type: String},
        position: {type: String},
        showCondition: {type: String},
        showConditionPerDayValue: {type: String},
        triggerOnExit: {
            type: Boolean,
            default: false
        },
        triggerOnScroll: {
            type: Boolean,
            default: false
        },
        triggerOnScrollValue: {type: String},
        triggerOnTimeOnPage: {
            type: Boolean,
            default: false
        },
        triggerOnTimeOnPageValue: {type: String},
        background: {type: String}
    },
    data() {
        return {
            showModal: false,
            scrollTimeout: null,
        };
    },
    computed: {
        calculatedHeight() {
            return this.height === 'fitToContent' ? 'fit-content' : '100vh';
        },
    },
    mounted() {
        if (this.triggerOnExit) {
            window.addEventListener("beforeunload", this.handleBeforeUnload)
            window.addEventListener("visibilitychange", this.handleVisibilityChange)
            window.addEventListener("blur", this.handleOnWindowBlur)
        }

        if (this.triggerOnScroll) {
            window.addEventListener("scroll", this.triggerOnScrollHandler)
        }

        if (this.triggerOnTimeOnPage) {
            setTimeout(() => {
                this.show();
            }, this.triggerOnTimeOnPageValue * 1000);
        }
    },
    beforeDestroy() {
        if (this.triggerOnScroll) {
            window.removeEventListener("scroll", this.triggerOnScrollHandler)
        }

        if (this.triggerOnExit) {
            window.removeEventListener("beforeunload", this.handleBeforeUnload)
            window.removeEventListener("visibilitychange", this.handleVisibilityChange)
            window.removeEventListener("blur", this.handleOnWindowBlur)
            localStorage.removeItem('onExitTriggered')
        }
    },
    methods: {
        show() {
            switch (this.showCondition) {
                case 'everyTime':
                    this.showModal = true;
                    break
                case 'oneTimeOnly':
                    if (localStorage.getItem('modalShownCount') === null) {
                        localStorage.setItem('modalShownCount', JSON.stringify({
                            count: 1,
                            timestamp: new Date().valueOf()
                        }));
                        this.showModal = true;
                    }
                    break
                case 'perDay': {
                    const modalShownCount = JSON.parse(localStorage.getItem('modalShownCount'));
                    if (modalShownCount === null) {
                        localStorage.setItem('modalShownCount', JSON.stringify({
                            count: 1,
                            timestamp: new Date().valueOf()
                        }));
                        this.showModal = true;
                    } else if (modalShownCount.timestamp < new Date().setHours(0, 0, 0, 0) && modalShownCount.count <= this.showConditionPerDayValue) {
                        localStorage.setItem('modalShownCount', JSON.stringify({
                            count: modalShownCount.count + 1,
                            timestamp: new Date().valueOf()
                        }));
                        this.showModal = true;
                    }
                }
                    break
                default:
                    break
            }
        },
        hide() {
            this.showModal = false;
        },
        triggerOnScrollHandler() {
            let scrollTop = window.scrollY;
            let docHeight = document.body.offsetHeight;
            let winHeight = window.innerHeight;
            let scrollPercent = scrollTop / (docHeight - winHeight);
            let scrollPercentRounded = Math.round(scrollPercent * 100);
            if (scrollPercentRounded == this.triggerOnScrollValue) {
                if (this.scrollTimeout) {
                    clearTimeout(this.scrollTimeout);
                }
                this.scrollTimeout = setTimeout(() => {
                    this.show();
                }, 200);
            }
        },
        handleBeforeUnload(e) {
            if (!localStorage.getItem('onExitTriggered')) {
                e.preventDefault()
                e.returnValue = "";
                this.show();
                localStorage.setItem('onExitTriggered', true);
            }
        },
        handleVisibilityChange() {
            if (!localStorage.getItem('onExitTriggered')) {
                if (document.visibilityState === 'hidden') {
                    this.show();
                    localStorage.setItem('onExitTriggered', true);
                }
            }
        },
        handleOnWindowBlur() {
            if (!localStorage.getItem('onExitTriggered')) {
                this.show();
                localStorage.setItem('onExitTriggered', true);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.modal-container {
    position: fixed;
    bottom: 0;
    left: unset;
    z-index: 99;
    right: 0;
    display: block;
    //   transform: translateY(-50%);
    height: max-content;
    width: max-content;
    background: white;
    min-width: 150px;
    min-height: 150px;
    box-shadow: 0px 0px 2.1px rgba(0, 0, 0, 0.037),
    0px 0px 5.1px rgba(0, 0, 0, 0.053),
    0px 0px 9.5px rgba(0, 0, 0, 0.065),
    0px 0px 17px rgba(0, 0, 0, 0.077),
    0px 0px 31.8px rgba(0, 0, 0, 0.093),
    0px 0px 76px rgba(0, 0, 0, 0.13);

    .modal-snippet {
        &__image {
            img {
                max-height: 267px;
                width: 100%;
                object-fit: contain;
            }
        }

        &__content {
            padding: 20px 60px;

            h1, h2, h3, h4, h5, h6 {
                text-transform: uppercase;
            }

            .toolbox-element.wysiwyg {
                margin: unset;
                max-width: unset;
            }

            .btn {
                width: 100%;
            }
        }
    }

    // close button
    button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 15px;
        color: white;
        stroke: white;
        cursor: pointer;
        background: none;
        border: none;

        i {
            font-size: 20px;
            text-shadow: 0px 0px 0.7px rgba(0, 0, 0, 0.281),
            0px 0px 1.6px rgba(0, 0, 0, 0.404),
            0px 0px 3px rgba(0, 0, 0, 0.5),
            0px 0px 5.4px rgba(0, 0, 0, 0.596),
            0px 0px 10px rgba(0, 0, 0, 0.719),
            0px 0px 24px rgba(0, 0, 0, 1);
        }
    }

    &-blur {
//   backdrop-filter: blur(10px);
    }

    &-darker {
//   background: rgba(0, 0, 0, 0.5);
    }

    &-top-center > .v-overlay__content {
        top: 0;
    }

    &-top-left > .v-overlay__content {
        top: 0;
        left: 0;
    }

    &-top-right > .v-overlay__content {
        top: 0;
        right: 0;
    }

    &-bottom-center > .v-overlay__content {
        bottom: 0;
    }

    &-bottom-left > .v-overlay__content {
        bottom: 0;
        left: 0;
    }

    &-bottom-right > .v-overlay__content {
        bottom: 0;
        right: 0;
    }

    &-center-left > .v-overlay__content {
        left: 0;
    }

    &-center-right > .v-overlay__content {
        right: 0;
    }
}

@media screen and (max-width: 768px) {
    .modal-container {
        right: 50%;
        transform: translateX(50%);
    }
}
</style>

