var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-call-back-service",
        "dialog-class": "modal-call-back-service",
        "modal-footer": "no",
        "hide-backdrop": _vm.hideBackdrop,
        "hide-footer": true,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("i", { staticClass: "cli cli-telephone" }),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("site.form.call_back_service.phone")) + " "
                ),
                _c("a", { attrs: { href: "tel:+43535665005" } }, [
                  _vm._v("+43 5356 65005"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("modal-call-back-service")
                    },
                  },
                },
                [_c("i", { staticClass: "cli cli-times" })]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "modal-body__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.send.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "modal-body__headline" }, [
            _c("p", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("site.form.call_back_service.headline")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("site.form.call_back_service.sub_headline")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("site.form.inquiry.first_name") },
            model: {
              value: _vm.firstName,
              callback: function ($$v) {
                _vm.firstName = $$v
              },
              expression: "firstName",
            },
          }),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("site.form.inquiry.last_name") },
            model: {
              value: _vm.lastName,
              callback: function ($$v) {
                _vm.lastName = $$v
              },
              expression: "lastName",
            },
          }),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { placeholder: _vm.$t("site.form.inquiry.phone_number") },
            model: {
              value: _vm.phoneNumber,
              callback: function ($$v) {
                _vm.phoneNumber = $$v
              },
              expression: "phoneNumber",
            },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "acceptance",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("site.form.call_back_service.acceptance")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "modal-body__button",
                  attrs: { type: "submit", variant: "primary" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("site.form.inquiry.submit")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }