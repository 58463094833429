<template>
    <div class="references-slider">
        <div class="references-slider-wrapper">
            <div class="keen-slider" ref="referenceSlider">
                <slot name="items"></slot>
            </div>
            <button class="carousel-control-prev" @click="referenceSlider.prev()">
                <span class="carousel-control-prev-icon"></span>
            </button>
            <button class="carousel-control-next" @click="referenceSlider.next()">
                <span class="carousel-control-next-icon"></span>
            </button>
        </div>
        <ol v-if="referenceSlider" class="carousel-indicators">
            <li
                v-for="(slide, idx) in dotHelper"
                @click="referenceSlider.moveToSlideRelative(idx)"
                :class="{dot: true, active: current === idx}"
                :key="idx"
            ></li>
        </ol>
    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
    data: () => ({
        current: 0,
        referenceSlider: null
    }),
    computed: {
        dotHelper() {
            let mobile = window.matchMedia('(max-width: 768px)').matches;
            return this.referenceSlider ? [...Array(this.referenceSlider.details().size).keys()].filter((x, i) => i % (mobile ? 1 : 3) === 0) : [];
        }
    },
    mounted() {
        this.referenceSlider = new KeenSlider(this.$refs.referenceSlider, {
            initial: this.current,
            slidesPerView: 3,
            spacing: 50,
            slideChanged: s => {
                this.current = s.details().relativeSlide;
            },
            breakpoints: {
                '(max-width: 768px)': {
                    slidesPerView: 1,
                    spacing: 0
                }
            }
        });
    },
    beforeDestroy() {
        if (this.referenceSlider) this.referenceSlider.destroy();
    }
}
</script>