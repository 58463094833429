<template>
    <div class="team-grid-wrapper container">
        <div :class="{'team-grid-member-wrapper': !mobile, 'keen-slider': mobile}" ref="teamSlider">
            <slot name="items"></slot>
        </div>
        <div v-if="mobile">
            <div class="carousel-control-prev" @click="teamSlider.prev()">
                <i class="cli cli-swipe-left"></i>
            </div>
            <div class="carousel-control-next" @click="teamSlider.next()">
                <i class="cli cli-swipe-right"></i>
            </div>
            <ol class="carousel-indicators">
                <li
                    v-for="(slide, idx) in dotHelper"
                    @click="teamSlider.moveToSlideRelative(idx)"
                    :class="{dot: true, active: current === idx}"
                    :key="idx"
                ></li>
            </ol>
        </div>
    </div>
</template>

<script>
import KeenSlider from "keen-slider";

export default {
    computed: {
        dotHelper() {
            return this.teamSlider ? [...Array(this.teamSlider.details().size).keys()] : [];
        }
    },
    data: () => ({
        current: 0,
        teamSlider: null,
        mobile: null
    }),
    created() {
        this.mobile = window.matchMedia('(max-width: 576px)').matches;
    },
    mounted() {
        if (this.mobile) {
            this.teamSlider = new KeenSlider(this.$refs.teamSlider, {
                initial: this.current,
                slidesPerView: 1,
                slideChanged: s => {
                    this.current = s.details().relativeSlide;
                }
            });
        }
    },
    beforeDestroy() {
        if (this.teamSlider) this.teamSlider.destroy();
    }
}
</script>