import Vue from 'vue';

import ModalLogin from'./ModalLogin'
import ModalRequestAccess from'./ModalRequestAccess'
import ModalPasswordReset from'./ModalPasswordReset'
import ModalPasswordResetForm from'./ModalPasswordResetForm'

Vue.component('secret-sales-modal-login', ModalLogin);
Vue.component('secret-sales-modal-request-access', ModalRequestAccess);
Vue.component('secret-sales-modal-password-reset', ModalPasswordReset);
Vue.component('secret-sales-modal-password-reset-form', ModalPasswordResetForm);