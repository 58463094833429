var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-grid-wrapper container" }, [
    _c(
      "div",
      {
        ref: "teamSlider",
        class: {
          "team-grid-member-wrapper": !_vm.mobile,
          "keen-slider": _vm.mobile,
        },
      },
      [_vm._t("items")],
      2
    ),
    _vm._v(" "),
    _vm.mobile
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "carousel-control-prev",
              on: {
                click: function ($event) {
                  return _vm.teamSlider.prev()
                },
              },
            },
            [_c("i", { staticClass: "cli cli-swipe-left" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "carousel-control-next",
              on: {
                click: function ($event) {
                  return _vm.teamSlider.next()
                },
              },
            },
            [_c("i", { staticClass: "cli cli-swipe-right" })]
          ),
          _vm._v(" "),
          _c(
            "ol",
            { staticClass: "carousel-indicators" },
            _vm._l(_vm.dotHelper, function (slide, idx) {
              return _c("li", {
                key: idx,
                class: { dot: true, active: _vm.current === idx },
                on: {
                  click: function ($event) {
                    return _vm.teamSlider.moveToSlideRelative(idx)
                  },
                },
              })
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }