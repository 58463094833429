var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.bookmarks && _vm.bookmarks.length > 0
      ? _c("div", { staticClass: "bookmarks__list-container container" }, [
          _c(
            "div",
            { staticClass: "bookmarks__list" },
            _vm._l(_vm.bookmarks, function (property) {
              return _c(
                "div",
                { staticClass: "bookmarks__list-item" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { value: property.id },
                      model: {
                        value: _vm.properties,
                        callback: function ($$v) {
                          _vm.properties = $$v
                        },
                        expression: "properties",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(property.title) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "bookmarks__list-item-icon" }, [
                    _c("i", { staticClass: "cli cli-location" }),
                    _vm._v(_vm._s(property.cityName) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "bookmarks__list-item-icon is--align-end" },
                    [
                      _c("i", { staticClass: "cli cli-search" }),
                      _vm._v(
                        "#" + _vm._s(property.objectNrExtern) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { ref: "inquiryFormContainer", staticClass: "inquiry-form" }, [
      _c(
        "div",
        { staticClass: "inquiry-form__container" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    !_vm.requestFinished
                      ? _c(
                          "form",
                          {
                            attrs: { novalidate: "" },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return handleSubmit(_vm.finalSubmit)
                              },
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "div",
                                  { staticClass: "checkboxes" },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "has--border-right",
                                        attrs: {
                                          name: "check-button",
                                          value: "mr",
                                        },
                                        model: {
                                          value: _vm.salutation,
                                          callback: function ($$v) {
                                            _vm.salutation = $$v
                                          },
                                          expression: "salutation",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "site.form.inquiry.salutation_mr"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          name: "check-button",
                                          value: "ms",
                                        },
                                        model: {
                                          value: _vm.salutation,
                                          callback: function ($$v) {
                                            _vm.salutation = $$v
                                          },
                                          expression: "salutation",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "site.form.inquiry.salutation_ms"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "required",
                                    name: _vm.$t(
                                      "site.form.inquiry.first_name"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-input", {
                                              class: {
                                                "has--errors":
                                                  errors.length > 0,
                                              },
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "site.form.inquiry.first_name"
                                                ),
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.firstName,
                                                callback: function ($$v) {
                                                  _vm.firstName = $$v
                                                },
                                                expression: "firstName",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "required",
                                    name: _vm.$t("site.form.inquiry.last_name"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-input", {
                                              class: {
                                                "has--errors":
                                                  errors.length > 0,
                                              },
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "site.form.inquiry.last_name"
                                                ),
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.lastName,
                                                callback: function ($$v) {
                                                  _vm.lastName = $$v
                                                },
                                                expression: "lastName",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "required|email",
                                    name: _vm.$t("site.form.inquiry.email"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-input", {
                                              class: {
                                                "has--errors":
                                                  errors.length > 0,
                                              },
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "site.form.inquiry.email"
                                                ),
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.email,
                                                callback: function ($$v) {
                                                  _vm.email = $$v
                                                },
                                                expression: "email",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: _vm.$t(
                                      "site.form.inquiry.phone_number"
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "site.form.inquiry.phone_number"
                                                ),
                                              },
                                              model: {
                                                value: _vm.phoneNumber,
                                                callback: function ($$v) {
                                                  _vm.phoneNumber = $$v
                                                },
                                                expression: "phoneNumber",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: `${
                                      _vm.messageIsOptional ? "" : "required"
                                    }`,
                                    name: _vm.messageIsOptional
                                      ? _vm.$t(
                                          "site.form.inquiry.message_optional"
                                        )
                                      : _vm.$t("site.form.inquiry.message"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c("b-form-textarea", {
                                              class: {
                                                "has--errors":
                                                  errors.length > 0,
                                              },
                                              attrs: {
                                                rows: "10",
                                                placeholder:
                                                  _vm.messageIsOptional
                                                    ? _vm.$t(
                                                        "site.form.inquiry.message_optional"
                                                      )
                                                    : _vm.$t(
                                                        "site.form.inquiry.message"
                                                      ),
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.message,
                                                callback: function ($$v) {
                                                  _vm.message = $$v
                                                },
                                                expression: "message",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _vm.showAddressForm
                                  ? _c("div", { staticClass: "address-form" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-9" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                rules: `${
                                                  _vm.showAddressForm
                                                    ? "required"
                                                    : ""
                                                }`,
                                                name: _vm.$t(
                                                  "site.form.inquiry.street"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          errors,
                                                          function (error) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          class: {
                                                            "has--errors":
                                                              errors.length > 0,
                                                          },
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "site.form.inquiry.street"
                                                            ),
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.address
                                                                .street,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.address,
                                                                "street",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "address.street",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                rules: `${
                                                  _vm.showAddressForm
                                                    ? "required"
                                                    : ""
                                                }`,
                                                name: _vm.$t(
                                                  "site.form.inquiry.house_number"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          errors,
                                                          function (error) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          class: {
                                                            "has--errors":
                                                              errors.length > 0,
                                                          },
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "site.form.inquiry.house_number"
                                                            ),
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.address
                                                                .houseNumber,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.address,
                                                                "houseNumber",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "address.houseNumber",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-4" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                rules: `${
                                                  _vm.showAddressForm
                                                    ? "required"
                                                    : ""
                                                }`,
                                                name: _vm.$t(
                                                  "site.form.inquiry.postcode"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          errors,
                                                          function (error) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          class: {
                                                            "has--errors":
                                                              errors.length > 0,
                                                          },
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "site.form.inquiry.postcode"
                                                            ),
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.address
                                                                .postcode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.address,
                                                                "postcode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "address.postcode",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-8" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                rules: `${
                                                  _vm.showAddressForm
                                                    ? "required"
                                                    : ""
                                                }`,
                                                name: _vm.$t(
                                                  "site.form.inquiry.city"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          errors,
                                                          function (error) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("b-form-input", {
                                                          class: {
                                                            "has--errors":
                                                              errors.length > 0,
                                                          },
                                                          attrs: {
                                                            placeholder: _vm.$t(
                                                              "site.form.inquiry.city"
                                                            ),
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.address.city,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.address,
                                                                "city",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "address.city",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-md-12" },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                rules: `${
                                                  _vm.showAddressForm
                                                    ? "required"
                                                    : ""
                                                }`,
                                                name: _vm.$t(
                                                  "site.form.inquiry.country"
                                                ),
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          errors,
                                                          function (error) {
                                                            return _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "input-error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("b-form-select", {
                                                          staticClass:
                                                            "form-control",
                                                          class: {
                                                            "has--errors":
                                                              errors.length > 0,
                                                          },
                                                          attrs: {
                                                            options:
                                                              _vm.sortedCountries,
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.address
                                                                .country,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.address,
                                                                "country",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "address.country",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "required-fields-notice",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "site.form.inquiry.required_fields_notice"
                                      )
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: { required: { allowFalse: false } },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass: "bottom-check",
                                                class: {
                                                  "has--errors":
                                                    errors.length > 0 > 0,
                                                },
                                                attrs: {
                                                  name: "check-button",
                                                  required: "",
                                                },
                                                model: {
                                                  value: _vm.acceptance,
                                                  callback: function ($$v) {
                                                    _vm.acceptance = $$v
                                                  },
                                                  expression: "acceptance",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "site.form.inquiry.acceptance"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(errors, function (error) {
                                              return _c(
                                                "p",
                                                { staticClass: "input-error" },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(error) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-alert",
                                  {
                                    staticClass: "mt-3",
                                    attrs: {
                                      variant: "danger",
                                      show: _vm.requestFinished && !_vm.success,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("site.ui.inquiry.error")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-center mt-2",
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          type: "submit",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("site.form.inquiry.submit")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.requestFinished && _vm.success
            ? _c("div", { staticClass: "frame" }, [
                _c("div", { staticClass: "position-relative" }, [
                  _c("div", { staticClass: "text-info" }, [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("site.ui.inquiry_form.dialog_title")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("site.ui.inquiry_form.dialog_text")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "inquiry-cta" }, [
      _c("div", { staticClass: "cta-item d-flex align-items-center mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "cta-item__inner d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row",
          },
          [
            _c("div", { staticClass: "brick-special-heading mb-3 mb-md-0" }, [
              _c(
                "h3",
                { staticClass: "first-title text-uppercase text-md-left" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("site.ui.inquiry_form.call_us.title")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "second-title text-uppercase text-md-left" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("site.ui.inquiry_form.call_us.sub_title")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-button",
              { attrs: { variant: "primary", href: "tel:+43535665005" } },
              [_vm._v("\n          Tel. +43 5356 65005\n        ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cta-item d-flex align-items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "cta-item__inner d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row",
          },
          [
            _c("div", { staticClass: "brick-special-heading mb-3 mb-md-0" }, [
              _c(
                "h3",
                { staticClass: "first-title text-uppercase text-md-left" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("site.ui.inquiry_form.write_us.title")) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "second-title text-uppercase text-md-left" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("site.ui.inquiry_form.write_us.sub_title")
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  href: "mailto:info@cumlaudeimmobilia.com",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("site.form.inquiry_form.write_us.send_mail")
                    ) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }