<template>
    <b-modal id="modal-secret-login" dialog-class="modal-secret-sales modal-secret-login" modal-footer="no"
             :hide-backdrop="hideBackdrop">
        <p class="modal-secret-sales__title"> {{ $t('site.ui.navigation.secret_sales.title') }}</p>

        <b-alert variant="danger" :show="requestFinished && error" fade>
            {{ $t('site.ui.secret_sales.login.error') }}
        </b-alert>

        <div class="form-group">
            <b-form-input v-model="email" type="email" class="form-control" :placeholder="$t('site.form.login.email')"/>
        </div>
        <div class="form-group">
            <b-form-input v-model="password" type="password" class="form-control"
                          :placeholder="$t('site.form.login.password')"/>
        </div>
        <b-button variant="primary" @click="doLogin()">
            {{ $t('site.form.login.submit') }}
        </b-button>
        <div class="bottom">
            <a href="#" v-b-modal.modal-secret-request-access @click="$bvModal.hide('modal-secret-login')">
                {{ $t('site.ui.navigation.secret_sales.request_access') }}
            </a>
            <a href="#" v-b-modal.modal-secret-password-reset @click="$bvModal.hide('modal-secret-login')">
                {{ $t('site.ui.navigation.secret_sales.forgot_password') }}
            </a>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        apiEndpoint: String,
        loginSuccessPath: String
    },
    data: () => ({
        email: null,
        password: null,
        requestFinished: false,
        error: false
    }),
    computed: {
        hideBackdrop() {
            return window.matchMedia('(min-width: 992px)').matches;
        }
    },
    methods: {
        doLogin() {
            axios.post(this.apiEndpoint, {
                username: this.email,
                password: this.password
            })
                .then(res => res.data)
                .then(res => {
                    this.requestFinished = true;
                    this.error = false;

                    console.log('login success', res);
                    window.location.href = this.loginSuccessPath;
                })
                .catch(err => {
                    this.requestFinished = true;
                    this.error = true;
                    console.log('login error', err);
                })

        }
    }
}
</script>