<template>
    <div class="bookmark-action-wrapper" @click="toggle">
        <i v-if="enabled" class="bookmark-icon cli cli-heart"></i>
        <i v-else class="bookmark-icon cli cli-heart-outline"></i>
        <slot name="text"></slot>
    </div>
</template>

<script>
export default {
    props: {
        active: Number,
        propertyId: Number
    },
    data: () => ({
        enabled: 0
    }),
    created() {
        this.enabled = this.active;
    },
    methods: {
        toggle(e) {
            e.preventDefault();

            if (this.enabled) {
                this.remove();
            } else {
                this.add();
            }
        },
        add() {
            this.enabled = 1;

            axios.post('/' + this.$locale + '/bookmarks/add/' + this.propertyId)
                .then(res => res.data)
                .then(res => {
                    this.$store.commit('addToBookmarks');
                })
                .catch(err => {
                    console.log('could not add property', err);
                });
        },
        remove() {
            this.enabled = 0;

            axios.post('/' + this.$locale + '/bookmarks/remove/' + this.propertyId)
                .then(res => res.data)
                .then(res => {
                    this.$store.commit('removeFromBookmarks');
                })
                .catch(err => {
                    console.log('could not remove property', err);
                });
        }
    }
}
</script>