var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "sideButtons", staticClass: "side-buttons" },
    [
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          _vm.mobileOpen
            ? _c("a", { key: "contact", attrs: { href: _vm.contactUrl } }, [
                _c("span", { staticClass: "item chat" }, [
                  _c("i", { staticClass: "cli cli-chat" }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.mobileOpen
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-call-back-service",
                      modifiers: { "modal-call-back-service": true },
                    },
                  ],
                  key: "tel",
                  attrs: { variant: "custom" },
                },
                [
                  _c("span", { staticClass: "item telephone" }, [
                    _c("i", { staticClass: "cli cli-telephone" }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mobileOpen
            ? _c("a", { key: "bookmarks", attrs: { href: _vm.bookmarksUrl } }, [
                _c("span", { key: "bookmarks", staticClass: "item heart" }, [
                  _c("i", { staticClass: "cli cli-heart" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.$store.state.bookmarks.counter)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.mobileOpen
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-share",
                      modifiers: { "modal-share": true },
                    },
                  ],
                  key: "share",
                  attrs: { variant: "custom" },
                },
                [
                  _c("span", { staticClass: "item share" }, [
                    _c("i", { staticClass: "cli cli-share" }),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("inquiry-call-back-service", {
        attrs: { "api-endpoint": _vm.callBackServiceUrl },
      }),
      _vm._v(" "),
      _c("modal-share"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.showTriggerButton
            ? _c(
                "b-button",
                {
                  attrs: { variant: "custom" },
                  on: {
                    click: function ($event) {
                      _vm.mobileOpen = !_vm.mobileOpen
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "item mobile-trigger animate__animated animate__pulse animate__infinite",
                    },
                    [
                      _c("div", { staticClass: "inner" }, [
                        _vm.mobileOpen
                          ? _c("i", { staticClass: "cli cli-times" })
                          : _c("i", {
                              staticClass: "cli cli-dots-three-vertical",
                            }),
                        _vm._v(" "),
                        _vm.$store.state.bookmarks.counter > 0 &&
                        _vm.mobileOpen == false
                          ? _c("span", { staticClass: "badge" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$store.state.bookmarks.counter) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }