export const search = {
    state: {
        open: false
    },
    getters: {
        isSearchOpen: state => state.open
    },
    mutations: {
        toggleOpenState(state) {
            state.open = !state.open
        }
    }
}