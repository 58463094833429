<template>
  <div
    ref="sideButtons"
    class="side-buttons"
  >
    <transition-group name="fade">
      <a
        v-if="mobileOpen"
        key="contact"
        :href="contactUrl"
      >
        <span class="item chat">
          <i class="cli cli-chat" />
        </span>
      </a>

      <b-button
        v-if="mobileOpen"
        key="tel"
        v-b-modal.modal-call-back-service
        variant="custom"
      >
        <span class="item telephone">
          <i class="cli cli-telephone" />
        </span>
      </b-button>

      <a
        v-if="mobileOpen"
        key="bookmarks"
        :href="bookmarksUrl"
      >
        <span
          key="bookmarks"
          class="item heart"
        >
          <i class="cli cli-heart" />
          <span class="count">{{ $store.state.bookmarks.counter }}</span>
        </span>
      </a>
      <b-button
        v-if="mobileOpen"
        key="share"
        v-b-modal.modal-share
        variant="custom"
      >
        <span class="item share">
          <i class="cli cli-share" />
        </span>
      </b-button>
    </transition-group>

    <inquiry-call-back-service :api-endpoint="callBackServiceUrl" />
    <modal-share />

    <transition name="fade">
      <b-button
        v-if="showTriggerButton"
        variant="custom"
        @click="mobileOpen = !mobileOpen"
      >
        <div class="item mobile-trigger animate__animated animate__pulse animate__infinite">
          <div class="inner">
            <i
              v-if="mobileOpen"
              class="cli cli-times"
            />
            <i
              v-else
              class="cli cli-dots-three-vertical"
            />

            <span
              v-if="$store.state.bookmarks.counter > 0 && mobileOpen == false"
              class="badge"
            >
              {{ $store.state.bookmarks.counter }}
            </span>
          </div>
        </div>
      </b-button>
    </transition>
  </div>
</template>

<script>
export default {
    props: {
        bookmarksUrl: String,
        callBackServiceUrl: String,
        contactUrl: String,
        hideTriggerButton: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        mobileOpen: false,
        showTriggerButton: true
    }),
    created() {
        if (this.hideTriggerButton) {
            this.showTriggerButton = false;
        }
    },
    mounted() {
        if (this.hideTriggerButton) {
            let _self = this;

            window.onscroll = function (e) {
                _self.showTriggerButton = window.scrollY > 200;
            };
        }
    }
}
</script>
<style lang="scss">
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.side-buttons {
    position: fixed;
    z-index: 99;
    top: unset;
    bottom: 20px;
    right: 10px;
    transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    padding: 10px;

    .item {
        width: 38px;
        height: 42px;
        background: #1f3d45;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-left: 6px;
        box-shadow: 0 2px 8px 0 #00000033, 0 5px 2px -3px #0000001e, 0 3px 3px 0 #00000023;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 3px;
            width: 36px;
            height: 32px;
            border: 1px solid #a9b0af;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: 5px;
            width: 38px;
            height: 36px;
            border: 1px solid #a9b0af;
        }
    }

    .item.chat,
    .item.telephone {
        background: $navi;
    }

    .item.heart,
    .item.share {
        background: $purple;
    }

    button {
        color: $white !important;
        padding: 0 !important;
    }

    a {
        color: #fff;
        display: block;
    }

    a:hover {
        text-decoration: none;
    }

    .item.share .cli,
    .item.heart .cli {
        font-size: 24px;
    }

    .item.heart .count {
        position: absolute;
        z-index: 1;
        color: white;
        font-family: $headings-font-family;
        font-size: 11px;
    }


    .btn {
        display: block;
    }

    .item {
        width: 44px;
        height: 44px;
        border-radius: 40px;
        background: white !important;
        color: $navi;
        padding-left: 0;
        transition: ease-in-out 500ms all;

        .count {
            color: white;
        }

        &:after, &:before {
            display: none;
        }

        &.mobile-trigger {
            height: 60px;
            width: 60px;
            font-size: 22px;
            margin-top: 20px;
            display: flex;
            animation-duration: 2000ms;
            opacity: 1;

            .inner {
                position: relative;

                .badge {
                    width: 22px;
                    height: 22px;
                    border-radius: 22px;
                    background: $navi;
                    color: white;
                    font-size: 12px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: -14px;
                    right: -20px;
                    font-family: $headings-font-family;
                }
            }
        }
    }
}

</style>