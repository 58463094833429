<template>
    <b-modal id="modal-secret-password-reset"
             dialog-class="modal-secret-sales modal-secret-login-pw-recover" modal-footer="no"
             :hide-backdrop="hideBackdrop">
        <p class="modal-secret-sales__title">
            {{ $t('site.ui.navigation.secret_sales_password_reset.title') }}
        </p>
        <div class="step1" v-if="!requestFinished">
            <form @submit.prevent="doResetRequest">
                <div class="form-group">
                    <b-form-input
                        type="email"
                        class="form-control"
                        required
                        v-model="email"
                        :placeholder="$t('site.form.password_reset_request.email')"/>
                </div>
                <b-button variant="primary" type="submit">
                    {{ $t('site.form.password_reset_request.submit') }}
                </b-button>
            </form>

            <div class="bottom">
                <a href="#" v-b-modal.modal-secret-login @click="$bvModal.hide('modal-secret-password-reset')">
                    {{ $t('site.ui.navigation.secret_sales.login') }}
                </a>
                <a href="#" v-b-modal.modal-secret-request-access @click="$bvModal.hide('modal-secret-password-reset')">
                    {{ $t('site.ui.navigation.secret_sales.request_access') }}
                </a>
            </div>
        </div>
        <div class="step2" v-else-if="requestFinished && success">
            <div class="content" v-html="$t('site.ui.navigation.secret_sales_password_reset.success_content')"></div>
            <a href="#" class="d-block text-center my-2" @click="doResetRequest()">
                {{ $t('site.ui.navigation.secret_sales.resend_email') }}
            </a>
            <b-button variant="primary">
                {{ $t('site.form.secret_sales_password_reset.close') }}
            </b-button>
        </div>
        <div class="step2" v-else>
            <div class="content" v-html="$t('site.ui.navigation.secret_sales_password_reset.error_content')"></div>
            <b-button variant="primary" @click="requestFinished = false">
                {{ $t('site.form.secret_sales_password_reset.try_again') }}
            </b-button>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        apiEndpoint: String
    },
    computed: {
        hideBackdrop() {
            return window.matchMedia('(min-width: 992px)').matches;
        }
    },
    data: () => ({
        email: null,
        requestFinished: false,
        success: false
    }),
    methods: {
        doResetRequest() {
            this.requestFinished = false;
            this.success = false;

            axios.post(this.apiEndpoint, {
                email: this.email
            })
                .then(res => res.data)
                .then(res => {
                    this.requestFinished = true;
                    this.success = true;
                })
                .catch(err => {
                    this.requestFinished = true;
                    this.success = false;
                });
        }
    }
}
</script>

<style lang="scss">
@import "../../../scss/common/mixins";
@import "../../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
</style>