var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-password-reset-form",
        "dialog-class": "modal-password-reset-form",
        "modal-footer": "no",
        "hide-footer": true,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.password_reset.title"))),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("site.ui.password_reset.subtitle"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("modal-password-reset-form")
                    },
                  },
                },
                [_c("i", { staticClass: "cli cli-times" })]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "danger",
            show: _vm.requestFinished && !_vm.success,
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("site.ui.password_reset.error")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "success",
            show: _vm.requestFinished && _vm.success,
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("site.ui.password_reset.success")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit }) {
              return [
                !_vm.requestFinished && !_vm.success
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.doPasswordReset)
                          },
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|confirmed:confirmation",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _vm._l(errors, function (error) {
                                          return _c(
                                            "p",
                                            { staticClass: "input-error" },
                                            [_vm._v(_vm._s(error))]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "password",
                                            placeholder: _vm.$t(
                                              "site.form.password_reset.password"
                                            ),
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function ($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          [
                            _c("ValidationProvider", {
                              attrs: { vid: "confirmation" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _vm._l(errors, function (error) {
                                          return _c(
                                            "p",
                                            { staticClass: "input-error" },
                                            [_vm._v(_vm._s(error))]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "password",
                                            placeholder: _vm.$t(
                                              "site.form.password_reset.password_confirm"
                                            ),
                                          },
                                          model: {
                                            value: _vm.passwordConfirmation,
                                            callback: function ($$v) {
                                              _vm.passwordConfirmation = $$v
                                            },
                                            expression: "passwordConfirmation",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                          },
                          [
                            _c(
                              "b-button",
                              { attrs: { variant: "primary", type: "submit" } },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t("site.form.password_reset.submit")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }