var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("googlemaps-map", {
    attrs: { center: _vm.center, zoom: _vm.zoom, options: _vm.options },
    on: {
      "update:zoom": function ($event) {
        _vm.zoom = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }