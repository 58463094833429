<template>
    <b-modal id="modal-secret-request-access"
             dialog-class="modal-secret-sales modal-secret-request-access" modal-footer="no"
             :hide-backdrop="hideBackdrop">

        <p class="modal-secret-sales__title  mb-1">
            {{
                $t('site.ui.navigation.secret_sales_request_access.title')
            }}
        </p>
        <div class="content font-weight-light mb-2 text-center"
             v-html="$t('site.ui.navigation.secret_sales_request_access.text')"></div>

        <b-alert variant="danger" :show="requestFinished && !success">
            {{ $t('site.ui.navigation.secret_sales_request_access.error') }}
        </b-alert>
        <b-alert variant="success" :show="requestFinished && success">
            {{ $t('site.ui.navigation.secret_sales_request_access.success') }}
        </b-alert>

        <div v-if="!requestFinished">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(register)">

                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-group">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input v-model="request_access.firstname"
                                          :placeholder="$t('site.form.register.first_name')">
                            </b-form-input>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-group">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input v-model="request_access.lastname"
                                          :placeholder="$t('site.form.register.last_name')">
                            </b-form-input>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                        <div class="form-group">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input v-model="request_access.email"
                                          :placeholder="$t('site.form.register.email')">
                            </b-form-input>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form-group">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input type="password" v-model="request_access.password"
                                          :placeholder="$t('site.form.register.password')">
                            </b-form-input>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                        <div class="form-group">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-checkbox v-model="request_access.acceptance"
                                             name="check-button"
                                             class="bottom-check">
                                <span v-html="$t('site.form.register.acceptance')"></span>
                            </b-form-checkbox>
                        </div>
                    </ValidationProvider>
                    <div class="d-flex align-items-center justify-content-center">
                        <b-button variant="primary" type="submit">
                            {{ $t('site.form.register.submit') }}
                        </b-button>
                    </div>
                </form>
            </ValidationObserver>
        </div>


        <div class="bottom">
            <a href="#" v-b-modal.modal-secret-login @click="$bvModal.hide('modal-secret-request-access')">
                {{ $t('site.ui.navigation.secret_sales.login') }}
            </a>
            <a href="#" v-b-modal.modal-secret-password-reset @click="$bvModal.hide('modal-secret-request-access')">
                {{ $t('site.ui.navigation.secret_sales.forgot_password') }}
            </a>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        apiEndpoint: String
    },
    data: () => ({
        request_access: {
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            acceptance: null
        },
        requestFinished: false,
        success: false
    }),
    computed: {
        hideBackdrop() {
            return window.matchMedia('(min-width: 992px)').matches;
        }
    },
    methods: {
        register() {
            this.requestFinished = false;
            this.success = false;

            axios.post(this.apiEndpoint, {
                request_access: this.request_access
            })
                .then(res => res.data)
                .then(res => {
                    this.requestFinished = true;
                    this.success = true;

                    console.log('register success', res);
                })
                .catch(err => {
                    this.requestFinished = true;
                    this.success = false;

                    console.log('register error', err);
                });
        }
    }
}
</script>
<style lang="scss">
@import "../../../scss/common/mixins";
@import "../../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

#modal-secret-request-access {
    .content {
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .custom-checkbox {
        .custom-control-label {
            font-size: 12px;
        }
    }
}
</style>