var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "references-slider" }, [
    _c("div", { staticClass: "references-slider-wrapper" }, [
      _c(
        "div",
        { ref: "referenceSlider", staticClass: "keen-slider" },
        [_vm._t("items")],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "carousel-control-prev",
          on: {
            click: function ($event) {
              return _vm.referenceSlider.prev()
            },
          },
        },
        [_c("span", { staticClass: "carousel-control-prev-icon" })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "carousel-control-next",
          on: {
            click: function ($event) {
              return _vm.referenceSlider.next()
            },
          },
        },
        [_c("span", { staticClass: "carousel-control-next-icon" })]
      ),
    ]),
    _vm._v(" "),
    _vm.referenceSlider
      ? _c(
          "ol",
          { staticClass: "carousel-indicators" },
          _vm._l(_vm.dotHelper, function (slide, idx) {
            return _c("li", {
              key: idx,
              class: { dot: true, active: _vm.current === idx },
              on: {
                click: function ($event) {
                  return _vm.referenceSlider.moveToSlideRelative(idx)
                },
              },
            })
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }