<template>
  <b-modal
    id="modal-call-back-service"
    dialog-class="modal-call-back-service"
    modal-footer="no"
    :hide-backdrop="hideBackdrop"
    :hide-footer="true"
  >
    <template #modal-header>
      <i class="cli cli-telephone" />
      <p>{{ $t('site.form.call_back_service.phone') }} <a href="tel:+43535665005">+43 5356 65005</a></p>
      <div
        class="close"
        @click="$bvModal.hide('modal-call-back-service')"
      >
        <i class="cli cli-times" />
      </div>
    </template>
    <form
      class="modal-body__form"
      @submit.prevent="send"
    >
      <div class="modal-body__headline">
        <p class="title">
          {{ $t('site.form.call_back_service.headline') }}
        </p>
        <p class="subtitle">
          {{ $t('site.form.call_back_service.sub_headline') }}
        </p>
      </div>
      <b-form-input
        v-model="firstName"
        :placeholder="$t('site.form.inquiry.first_name')"
      />
      <b-form-input
        v-model="lastName"
        :placeholder="$t('site.form.inquiry.last_name')"
      />
      <b-form-input
        v-model="phoneNumber"
        :placeholder="$t('site.form.inquiry.phone_number')"
      />
      <div
        class="acceptance"
        v-html="$t('site.form.call_back_service.acceptance') "
      />
      <div class="d-flex justify-content-center">
        <b-button
          class="modal-body__button"
          type="submit"
          variant="primary"
        >
          {{ $t('site.form.inquiry.submit') }}
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
    props: {
        apiEndpoint: String
    },
    data: () => ({
        firstName: null,
        lastName: null,
        phoneNumber: null,
        time: null
    }),
    computed: {
        hideBackdrop() {
            return window.matchMedia('(min-width: 992px)').matches;
        }
    },
    methods: {
        send() {
            axios.post(this.apiEndpoint, {
                inquiry_call_back_service: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNumber,
                    time: this.time
                }
            })
                .then(res => res.data)
                .then(res => {
                    console.log('sucessfully sent mail', res);
                    this.$bvModal.hide('modal-call-back-service');
                    this.reset();

                })
                .catch(err => {
                    console.log('error in mail', err);
                    this.$bvModal.hide('modal-call-back-service');
                    this.reset();
                });

        },
        reset() {
            this.firstName = null;
            this.lastName = null;
            this.phoneNumber = null;
            this.time = null;
        }
    }
}
</script>