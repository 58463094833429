<template>
    <b-modal id="modal-share" dialog-class="modal-share" modal-footer="no" :hide-backdrop="hideBackdrop"
             :hide-footer="true">
        <template #modal-header>
            <p class="title">{{ $t('site.share_modal.headline') }}</p>
            <p class="subtitle">{{ $t('site.share_modal.sub_headline') }}</p>
            <div class="close" @click="$bvModal.hide('modal-share')">
                <i class="cli cli-times"></i>
            </div>
        </template>
        <p>{{ $t('site.share_modal.send_link') }}:</p>
        <ul>
            <li>
                <a :href="`https://wa.me/?text=${url}`" target="_blank">
                    <i class="cli cli-whatsapp"></i>
                </a>
            </li>
            <li>
                <a :href="`fb-messenger://share/?link=${url}`" target="_blank">
                    <i class="cli cli-messenger"></i>
                </a>
            </li>
            <li>
                <a :href="smsLink" target="_blank">
                    <i class="cli cli-sms"></i>
                </a>
            </li>
            <li>
                <a :href="`mailto:?subject=${url}`" target="_blank">
                    <i class="cli cli-email-mobile"></i>
                </a>
            </li>
        </ul>
        <p class="share">{{ $t('site.share_modal.share_page') }}:</p>
        <ul>
            <li>
                <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank">
                    <i class="cli cli-facebook"></i>
                </a>
            </li>
            <li>
                <a :href="`https://twitter.com/home?status=${url}`" target="_blank">
                    <i class="cli cli-twitter"></i>
                </a>
            </li>
            <li>
                <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" target="_blank">
                    <i class="cli cli-linkedin"></i>
                </a>
            </li>
        </ul>
    </b-modal>
</template>

<script>
export default {
    data: () => ({
        url: window.location.href
    }),
    computed: {
        hideBackdrop() {
            return window.matchMedia('(min-width: 992px)').matches;
        },
        smsLink() {
            var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

            if (isIOS) {
                return "sms:&body=" + this.url;
            } else {
                return "sms:?body=" + this.url;
            }
        }
    }
}
</script>