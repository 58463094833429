<template>
  <div>
    <div
      v-if="bookmarks && bookmarks.length > 0"
      class="bookmarks__list-container container"
    >
      <div class="bookmarks__list">
        <div
          v-for="property in bookmarks"
          class="bookmarks__list-item"
        >
          <b-form-checkbox
            v-model="properties"
            :value="property.id"
          >
            {{ property.title }}
          </b-form-checkbox>
          <span class="bookmarks__list-item-icon">
            <i class="cli cli-location" />{{ property.cityName }}
          </span>
          <span class="bookmarks__list-item-icon is--align-end">
            <i class="cli cli-search" />#{{ property.objectNrExtern }}
          </span>
        </div>
      </div>
    </div>
    <div
      ref="inquiryFormContainer"
      class="inquiry-form"
    >
      <div class="inquiry-form__container">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            v-if="!requestFinished"
            novalidate
            @submit.prevent="handleSubmit(finalSubmit)"
          >
            <b-form-group>
              <div class="checkboxes">
                <b-form-checkbox
                  v-model="salutation"
                  name="check-button"
                  class="has--border-right"
                  value="mr"
                >
                  {{ $t('site.form.inquiry.salutation_mr') }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="salutation"
                  name="check-button"
                  value="ms"
                >
                  {{ $t('site.form.inquiry.salutation_ms') }}
                </b-form-checkbox>
              </div>

              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                :name="$t('site.form.inquiry.first_name')"
              >
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
                <b-form-input
                  v-model="firstName"
                  :class="{'has--errors': errors.length > 0 }"
                  :placeholder="$t('site.form.inquiry.first_name')"
                  required
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                :name="$t('site.form.inquiry.last_name')"
              >
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
                <b-form-input
                  v-model="lastName"
                  :class="{'has--errors': errors.length > 0 }"
                  :placeholder="$t('site.form.inquiry.last_name')"
                  required
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                rules="required|email"
                :name="$t('site.form.inquiry.email')"
              >
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
                <b-form-input
                  v-model="email"
                  :class="{'has--errors': errors.length > 0 }"
                  :placeholder="$t('site.form.inquiry.email')"
                  required
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                :name="$t('site.form.inquiry.phone_number')"
              >
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
                <b-form-input
                  v-model="phoneNumber"
                  :placeholder="$t('site.form.inquiry.phone_number')"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${messageIsOptional ? '' : 'required'}`"
                :name="messageIsOptional ? $t('site.form.inquiry.message_optional') : $t('site.form.inquiry.message')"
              >
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
                <b-form-textarea
                  v-model="message"
                  :class="{'has--errors': errors.length > 0 }"
                  rows="10"
                  :placeholder="messageIsOptional ? $t('site.form.inquiry.message_optional') : $t('site.form.inquiry.message')"
                  required
                />
              </ValidationProvider>

              <div
                v-if="showAddressForm"
                class="address-form"
              >
                <div class="row">
                  <div class="col-md-9">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${showAddressForm ? 'required' : ''}`"
                      :name="$t('site.form.inquiry.street')"
                    >
                      <p
                        v-for="error in errors"
                        class="input-error"
                      >
                        {{ error }}
                      </p>
                      <b-form-input
                        v-model="address.street"
                        :class="{'has--errors': errors.length > 0 }"
                        :placeholder="$t('site.form.inquiry.street')"
                        required
                      />
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${showAddressForm ? 'required' : ''}`"
                      :name="$t('site.form.inquiry.house_number')"
                    >
                      <p
                        v-for="error in errors"
                        class="input-error"
                      >
                        {{ error }}
                      </p>
                      <b-form-input
                        v-model="address.houseNumber"
                        :class="{'has--errors': errors.length > 0 }"
                        :placeholder="$t('site.form.inquiry.house_number')"
                        required
                      />
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${showAddressForm ? 'required' : ''}`"
                      :name="$t('site.form.inquiry.postcode')"
                    >
                      <p
                        v-for="error in errors"
                        class="input-error"
                      >
                        {{ error }}
                      </p>
                      <b-form-input
                        v-model="address.postcode"
                        :class="{'has--errors': errors.length > 0 }"
                        :placeholder="$t('site.form.inquiry.postcode')"
                        required
                      />
                    </ValidationProvider>
                  </div>
                  <div class="col-md-8">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${showAddressForm ? 'required' : ''}`"
                      :name="$t('site.form.inquiry.city')"
                    >
                      <p
                        v-for="error in errors"
                        class="input-error"
                      >
                        {{ error }}
                      </p>
                      <b-form-input
                        v-model="address.city"
                        :class="{'has--errors': errors.length > 0 }"
                        :placeholder="$t('site.form.inquiry.city')"
                        required
                      />
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${showAddressForm ? 'required' : ''}`"
                      :name="$t('site.form.inquiry.country')"
                    >
                      <p
                        v-for="error in errors"
                        class="input-error"
                      >
                        {{ error }}
                      </p>
                      <b-form-select
                        v-model="address.country"
                        :class="{'has--errors': errors.length > 0 }"
                        :options="sortedCountries"
                        class="form-control"
                        required
                      />
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div
                class="required-fields-notice"
                v-html="$t('site.form.inquiry.required_fields_notice')"
              />

              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: { allowFalse: false } }"
              >
                <b-form-checkbox
                  v-model="acceptance"
                  :class="{'has--errors': errors.length > 0 > 0 }"
                  name="check-button"
                  class="bottom-check"
                  required
                >
                  <span v-html="$t('site.form.inquiry.acceptance')" />
                </b-form-checkbox>
                <p
                  v-for="error in errors"
                  class="input-error"
                >
                  {{ error }}
                </p>
              </ValidationProvider>
              <b-alert
                variant="danger"
                class="mt-3"
                :show="requestFinished && !success"
              >
                {{ $t('site.ui.inquiry.error') }}
              </b-alert>

              <div class="d-flex align-items-center justify-content-center mt-2">
                <b-button
                  variant="primary"
                  type="submit"
                >
                  {{ $t('site.form.inquiry.submit') }}
                </b-button>
              </div>
            </b-form-group>
          </form>
        </ValidationObserver>
        <div
          v-if="requestFinished && success"
          class="frame"
        >
          <div class="position-relative">
            <div class="text-info">
              <h2 class="title">
                {{ $t('site.ui.inquiry_form.dialog_title') }}
              </h2>
              <p class="text">
                {{ $t('site.ui.inquiry_form.dialog_text') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inquiry-cta">
      <div class="cta-item d-flex align-items-center mb-4">
        <div
          class="cta-item__inner d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row"
        >
          <div class="brick-special-heading  mb-3 mb-md-0">
            <h3 class="first-title text-uppercase text-md-left">
              {{ $t('site.ui.inquiry_form.call_us.title') }}
            </h3>
            <p class="second-title text-uppercase text-md-left">
              {{ $t('site.ui.inquiry_form.call_us.sub_title') }}
            </p>
          </div>
          <b-button
            variant="primary"
            href="tel:+43535665005"
          >
            Tel. +43 5356 65005
          </b-button>
        </div>
      </div>
      <div class="cta-item d-flex align-items-center">
        <div
          class="cta-item__inner d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row"
        >
          <div class="brick-special-heading mb-3 mb-md-0">
            <h3 class="first-title text-uppercase text-md-left">
              {{ $t('site.ui.inquiry_form.write_us.title') }}
            </h3>
            <p class="second-title text-uppercase text-md-left">
              {{ $t('site.ui.inquiry_form.write_us.sub_title') }}
            </p>
          </div>
          <b-button
            variant="primary"
            href="mailto:info@cumlaudeimmobilia.com"
          >
            {{ $t('site.form.inquiry_form.write_us.send_mail') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    apiEndpoint: String,
    propertyId: {
      type: String,
      default: null
    },
    bookmarks: {
      type: Array,
      default: []
    },
    showAddressForm: {
      type: Boolean,
      default: false
    },
    messageIsOptional: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    salutation: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    email: null,
    message: null,
    acceptance: false,
    state: {
      showPhoneNumberModal: false
    },
    address: {
      street: null,
      postcode: null,
      houseNumber: null,
      city: null,
      country: null,
    },
    properties: [],
    captchaValid: false,
    requestFinished: false,
    success: false,
    countries: [],
  }),
  computed: {
    sortedCountries() {
      let prio = ['DE', 'AT', 'CH'];
      let countries = this.countries.reduce((acc, element) => {
        if (prio.includes(element.cca2)) {
          return [{
            text: element.name.common,
            value: element.cca2
          }, ...acc];
        }
        return [...acc, {
          text: element.name.common,
          value: element.cca2
        }];
      }, []);

      countries.unshift({
        value: null,
        text: this.$t('site.form.inquiry.country'),
        disabled: true
      });

      return countries;
    }
  },
  created() {
    if (this.propertyId) {
      this.properties = this.propertyId;
    }
  },
  mounted() {
    axios.get('https://restcountries.com/v3/all')
        .then(res => res.data)
        .then(countries => {
          this.countries = countries;
        })
        .catch(err => {
          console.log('could not load countries');
        })
  },
  methods: {
    firstSubmit() {
      this.state.showPhoneNumberModal = true;
    },
    finalSubmit() {
      this.requestFinished = false;
      this.success = false;

      axios.post(this.apiEndpoint, {
        propertyId: this.properties,
        hasAddressData: this.showAddressForm,
        address: this.address,
        inquiry: {
          salutation: this.salutation,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          message: this.message,
          acceptance: this.acceptance
        }
      })
          .then(res => res.data)
          .then(res => {
            console.log('sucessfully sent mail', res);

            this.requestFinished = true;
            this.success = true;
          })
          .catch(err => {
            console.log('error in mail', err);

            this.requestFinished = true;
            this.success = false;
          });

    },
    closeDialog() {
      if (!this.requestFinished) {
        this.finalSubmit();
      }

      this.reset();
    },
    reset() {
      this.state.showPhoneNumberModal = false;
      this.salutation = null;
      this.firstName = null;
      this.lastName = null;
      this.phoneNumber = null;
      this.email = null;
      this.message = null;
      this.acceptance = false;
      this.properties = [];
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.inquiry-form {
  max-width: 820px;
  margin: 0 auto;

  .frame {
    width: 100%;
    border: solid 1px #1f3d45;
    padding: 56px 80px;
    @include noisyBackground;
    z-index: 20;

    @include media-breakpoint-down(md) {
      padding: 30px 25px;
    }
  }

  .inquiry-form__container {
    position: relative;

    .custom-checkbox {
      .custom-control-label {
        font-weight: normal;
        font-style: normal;
        letter-spacing: 3.7px;
        text-transform: uppercase;

        &:before {
          border-color: $navi;
          background: $navi;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: $navi;
        background: $navi;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        transform: translate(-6px, -1px);
      }

    }

    .required-fields-notice {
      font-size: 14px;
      font-style: italic;
      color: #b8b8b8;
    }

    .input-error {
      font-size: 12px;
      font-weight: bold;
      color: $navi;
      margin-bottom: 3px;
    }

    .checkboxes {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .has--border-right {
        border-right: 1px solid #aaa;
        margin-right: 30px;
      }

      .has--errors {
        .custom-control-label {
          &:before {
            border-color: $navi;
          }
        }
      }
    }

    .form-control {
      font-size: 16px;
      padding: 15px 30px;
      margin-bottom: 15px;
      font-weight: normal;
      color: $white;
      position: relative;
      background: $navi;
      @include grainOverlay();

      ::placeholder {
        color: #A9B0AF;
      }

      &.has--errors {
        border: 1px solid $danger;
      }

      @include media-breakpoint-down(sm) {
        padding: 15px;
      }
    }

    input[type="text"], input[type="email"], input[type="tel"], select {
      height: 65px;
    }

    .bottom-check {
      margin-top: 20px;
      padding: 0 0 0 34px;

      .custom-control-label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 22px;
        letter-spacing: normal;
        margin-right: 0;
        color: #494948;
        text-transform: none;
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

    }

  }

  .text-info {
    text-align: center;

    .title {
      font-size: 35px;
      line-height: 1.29;
      letter-spacing: 3.04px;
      text-align: center;
      color: #494948;
      border-bottom: 1px solid;
      padding-bottom: 7px;
      max-width: 420px;
      margin: auto;
      margin-bottom: 27px;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 26px;
        margin-bottom: 10px;
      }
    }

    .text {
      max-width: 550px;
      margin: auto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #494948;
      margin-bottom: 31px;
    }
  }

  .inputs {
    max-width: 340px;
    margin: auto;
  }

  button {
    min-width: 276px;

    @include media-breakpoint-down(sm) {
      min-width: 268px;
    }
  }
}

.inquiry-cta {
  margin-top: 50px;

  .cta-item {
    background: #ededed;
    padding: 30px 50px 25px;
    max-width: 820px;
    margin: 0 auto;

    .cta-item__inner {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    .brick-special-heading {
      .first-title {
        max-width: unset;
        margin: 0;
        font-size: 26px;

        @include media-breakpoint-down(md) {
          font-size: 22px;
        }

        @include media-breakpoint-up(md) {
          &:after {
            margin-left: 0;
          }
        }

      }

      .second-title {
        font-size: 18px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }
  }
}

</style>
