var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-search", class: { "is--open": _vm.isSearchOpen } },
    [
      _c(
        "form",
        { attrs: { action: _vm.targetPath, method: "GET" } },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: {
                  name: "query",
                  placeholder: _vm.$t("navbar.search.placeholder"),
                },
                model: {
                  value: _vm.query,
                  callback: function ($$v) {
                    _vm.query = $$v
                  },
                  expression: "query",
                },
              }),
              _vm._v(" "),
              _c("b-input-group-append", { on: { click: _vm.open } }, [
                _c("i", { staticClass: "cli cli-search" }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }