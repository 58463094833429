<template>
  <div
    class="main-navigation fixed-top"
    :class="{
      'nav-dark': isDark,
      'nav-transparent': isTransparent,
      'is-fixed': isScrolled,
      'is--search-open': isSearchOpen
    }"
  >
    <slot />
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    isScrolled: false
  }),
  computed: {
    ...mapGetters([
      'isSearchOpen'
    ])
  },
  mounted() {
    let self = this;

    window.document.addEventListener('scroll', function (event) {
      self.isScrolled = (window.pageYOffset > 50);
    });
  }
}
</script>