<template>
  <div class="newsletter-slide">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-9">
          <div class="newsletter-slide__inner row d-flex align-items-center">
            <div class="col-md-6 col-xl-5">
              <p class="newsletter-slide__title">
                {{ $t('property.newsletter_slide.title') }}
              </p>
              <p class="newsletter-slide__text">
                {{ $t('property.newsletter_slide.text') }}
              </p>
            </div>
            <div class="col-md-6 col-xl-7">
              <div v-if="!request.finished">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form
                    novalidate
                    @submit.prevent="handleSubmit(onSubmit)"
                  >
                    <div class="checkboxes">
                      <b-form-checkbox
                        v-model="inquiry.salutation"
                        name="check-button"
                        class="has--border-right"
                        value="mr"
                      >
                        {{ $t('site.form.inquiry.salutation_mr') }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="inquiry.salutation"
                        name="check-button"
                        value="ms"
                      >
                        {{ $t('site.form.inquiry.salutation_ms') }}
                      </b-form-checkbox>
                    </div>
                    <div class="row">
                      <div class="col-md-6 pr-md-1  mb-2">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          :name="$t('property.newsletter_slide.firstname_placeholder')"
                        >
                          <b-input
                            v-model="inquiry.firstName"
                            class="email-input"
                            type="text"
                            :class="{'has--errors': errors.length > 0 }"
                            :placeholder="$t('property.newsletter_slide.firstname_placeholder')"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6 pl-md-1  mb-2">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          :name="$t('property.newsletter_slide.lastname_placeholder')"
                        >
                          <b-input
                            v-model="inquiry.lastName"
                            class="email-input"
                            type="text"
                            :class="{'has--errors': errors.length > 0 }"
                            :placeholder="$t('property.newsletter_slide.lastname_placeholder')"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required|email"
                      :name="$t('property.newsletter_slide.email_placeholder')"
                    >
                      <b-input
                        v-model="inquiry.email"
                        class="email-input"
                        type="email"
                        :class="{'has--errors': errors.length > 0 }"
                        :placeholder="$t('property.newsletter_slide.email_placeholder')"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ required: { allowFalse: false } }"
                    >
                      <b-form-checkbox
                        v-model="inquiry.acceptance"
                        class="my-4"
                        :class="{'has--errors': errors.length > 0 }"
                        name="check-button"
                        required
                      >
                        <span v-html="$t('property.newsletter_slide.acceptance')" />
                      </b-form-checkbox>
                    </ValidationProvider>
                    <b-button
                      type="submit"
                      variant="secondary"
                    >
                      {{ $t('property.newsletter_slide.button') }}
                    </b-button>
                  </form>
                </ValidationObserver>
              </div>
              <div
                v-if="request.finished && request.success"
                class="form-success"
              >
                <p class="newsletter-slide__title">
                  {{ $t('property.newsletter_slide.success_title') }}
                </p>
                <p class="newsletter-slide__text">
                  {{ $t('property.newsletter_slide.success_text') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    apiEndpoint: String,
    propertyId: String
  },
  data: () => ({
    inquiry: {
      acceptance: false,
      email: null,
      firstName: null,
      lastName: null,
      salutation: null
    },
    request: {
      success: false,
      loading: false,
      finished: false
    }
  }),
  methods: {
    onSubmit() {
      this.request.finished = false;
      this.request.success = false;

      axios.post(this.apiEndpoint, {
        propertyId: this.propertyId,
        hasAddressData: false,
        inquiry: this.inquiry
      })
          .then(res => res.data)
          .then(res => {
            console.log('sucessfully sent mail', res);

            this.request.finished = true;
            this.request.success = true;

            // reset
            this.email = null
            this.acceptance = false
          })
          .catch(err => {
            console.log('error in mail', err);

            this.request.finished = true;
            this.request.success = false;
          });
    },
  }
}
</script>


<style lang="scss">
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.newsletter-slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-align: left;

  @include media-breakpoint-down(md) {
    text-align: center;
    font-size: 14px;
  }

  .newsletter-slide__inner {
    margin-top: 200px;

    @include media-breakpoint-up(lg) {
      margin-top: 300px;
    }
  }

  .newsletter-slide__title {
    font-family: $headings-font-family;
    color: white;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 3px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      padding-right: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }

    &:after {
      content: '';
      display: block;
      height: 0.75px;
      width: 300px;
      opacity: 0.5;
      background: white;
      margin: 15px 0 30px;

      @include media-breakpoint-down(lg) {
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 12px auto 15px;
      }
    }
  }

  .checkboxes {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .has--border-right {
      border-right: 1px solid #aaa;
      margin-right: 30px;
      padding-right: 30px;
    }

    .has--errors {
      .custom-control-label {
        &:before {
          border-color: $navi;
        }
      }
    }
  }

  .email-input {
    background: rgba(white, 0.5);
    border: 1px solid white;
    text-transform: uppercase;
    color: $navi;
    padding: 12px 24px;
    font-size: 18px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    &::placeholder {
      color: rgba($navi, 0.8);
    }
  }

  .custom-checkbox {
    font-style: italic;
    text-align: left;

    a {
      color: white;
      text-decoration: underline;
    }

    .custom-control-label {
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      margin: 0
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      margin: 0 -15px;
    }
  }

  .form-success {
    text-align: center;
    padding: 50px 0;

    @include media-breakpoint-up(md) {
      width: 60%;
      margin: 0 auto;
      padding: 0;
    }

    .newsletter-slide__title {
      padding: 0;

      &:after {
        display: none;
      }
    }
  }
}
</style>