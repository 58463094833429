<template>
    <div class="inner-form">

        <b-alert variant="success" :show="requestFinished && success">
            {{ $t('site.ui.newsletter.success') }}
        </b-alert>

        <b-alert variant="danger" :show="requestFinished && !success">
            <div v-if="!active">
                {{ $t('site.ui.newsletter.error') }}
            </div>
            <div v-else>
                {{ $t('site.ui.newsletterActive.error') }}
            </div>
        </b-alert>

        <template v-if="success === false">
            <div v-if="captchaVisible">
                <div class="form-group">
                    <vue-recaptcha :sitekey="siteKey" @verify="signUp()"></vue-recaptcha>
                </div>
            </div>
            <div v-else>
                <ValidationObserver v-slot="{ handleSubmit }">
<!--                    <form @submit.prevent="handleSubmit(firstSubmit)" novalidate>  -> disabled because vue recaptcha is not working now -->
                    <form @submit.prevent="handleSubmit(signUp)" novalidate>

                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input
                                :class="{'has--errors': errors.length > 0}"
                                v-model="subscriber.firstName"
                                :placeholder="$t('site.form.newsletter.first_name')"
                                class="name"></b-form-input>
                        </ValidationProvider>

                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input
                                :class="{'has--errors': errors.length > 0}"
                                v-model="subscriber.lastName" :placeholder="$t('site.form.newsletter.last_name')"
                                class="name"></b-form-input>
                        </ValidationProvider>

                        <ValidationProvider v-if="formType === 'standard' || formType ==='combination'" rules="required|email" v-slot="{ errors }">
                            <p class="input-error" v-for="error in errors">{{ error }}</p>
                            <b-form-input
                                :class="{'has--errors': errors.length > 0}"
                                v-model="subscriber.email" type="email"
                                :placeholder="$t('site.form.newsletter.email')"
                                class="email"></b-form-input>
                        </ValidationProvider>

                        <div class="news-container" v-if="formType === 'whatsapp' || formType ==='combination'">
                            <b-form-checkbox
                                v-if="formType ==='combination'"
                                v-model="subscriber.whatsappAcceptance"
                                name="checkbox-whatsapp-button"
                                class="bottom-check">
                                <span v-html="$t('site.form.newsletter.whatsapp.acceptance')"></span>
                            </b-form-checkbox>

                            <div class="news-container--phone" v-if="subscriber.whatsappAcceptance">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <p class="input-error" v-for="error in errors">{{ error }}</p>
                                    <VuePhoneNumberInput
                                        :translations="phoneInputTranslations"
                                        :class="{'has--errors': errors.length > 0}"
                                        v-model="subscriber.phone"
                                        default-country-code="DE"
                                        :only-countries="onlyCountries"
                                        @update="savePhoneMetadata"
                                    />
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="news-container">
                            <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                                <p class="input-error" v-for="error in errors">{{ error }}</p>
                                <b-form-checkbox v-model="subscriber.acceptance" name="check-button" class="bottom-check"
                                                 :class="{'has--errors': errors.length > 0}">
                                    <span v-html="$t('site.form.newsletter.acceptance')"></span>
                                </b-form-checkbox>
                            </ValidationProvider>
                        </div>

                        <div class="d-flex align-items-center justify-content-center">
                            <b-button type="submit" variant="secondary" class="submit">
                                {{ $t('site.form.newsletter.submit') }}
                            </b-button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </template>
    </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {VueRecaptcha},
    props: {
        apiEndpoint: String,
        formType: String
    },
    computed: {
        siteKey() {
            return document.querySelector('meta[name="recaptcha-site-key"]').content
        }
    },
    data: () => ({
        subscriber: {
            formType: null,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            acceptance: null,
            whatsappAcceptance: null,
        },
        onlyCountries: [
            "AT", "BE", "BG", "CH", "CZ", "DE", "DK", "ES", "FR", "HR", "HU", "IE", "IT", "LI", "LU", "NL", "NO", "PL", "SE"
        ],
        phoneMetadata: null,
        requestFinished: false,
        success: false,
        active: false,
        captchaVisible: false,
        captchaValid: false,
        phoneInputTranslations: {
            countrySelectorLabel: 'Ländercode',
            countrySelectorError: 'Wählen Sie ein Land',
            phoneNumberLabel: 'Telefonnummer',
            example: 'Beispiel: '
        }
    }),
    mounted() {
        if (this.formType === 'whatsapp') {
            this.subscriber.whatsappAcceptance = true;
        }
    },
    methods: {
        savePhoneMetadata(data) {
            this.phoneMetadata = data;
        },
        firstSubmit() {
            this.captchaVisible = true;
        },
        signUp() {
            this.requestFinished = false;
            this.success = false;
            this.subscriber.formType = this.formType;

            if (this.subscriber.whatsappAcceptance) {
                this.subscriber.phoneInternational = this.phoneMetadata['formatInternational'];
            }

            axios.post(this.apiEndpoint, this.subscriber)
                .then(res => {
                    this.requestFinished = true;
                    this.success = res.data.success;
                    this.active = res.data.active;
                })
                .catch(err => {
                    this.requestFinished = true;
                    this.success = false;
                });
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/common/mixins";
@import "../../scss/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.required-fields-notice {
    font-size: 14px;
    font-style: italic;
    color: white;
}

.input-error {
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-align: left;
    margin-bottom: 4px;
}

.form-control {
    &.has--errors {
        border: 1px solid white;
    }
}

.has--errors {
    .custom-control-label {
        &:before {
            border: 1px solid white;
        }
    }
}

.news-container {
    margin-bottom: 15px;

    &--phone {
        margin-bottom: 35px;
    }

    .custom-checkbox {
        margin: 0 !important;
    }
}

</style>
