var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-secret-login",
        "dialog-class": "modal-secret-sales modal-secret-login",
        "modal-footer": "no",
        "hide-backdrop": _vm.hideBackdrop,
      },
    },
    [
      _c("p", { staticClass: "modal-secret-sales__title" }, [
        _vm._v(" " + _vm._s(_vm.$t("site.ui.navigation.secret_sales.title"))),
      ]),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "danger",
            show: _vm.requestFinished && _vm.error,
            fade: "",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("site.ui.secret_sales.login.error")) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("b-form-input", {
            staticClass: "form-control",
            attrs: {
              type: "email",
              placeholder: _vm.$t("site.form.login.email"),
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("b-form-input", {
            staticClass: "form-control",
            attrs: {
              type: "password",
              placeholder: _vm.$t("site.form.login.password"),
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: { variant: "primary" },
          on: {
            click: function ($event) {
              return _vm.doLogin()
            },
          },
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("site.form.login.submit")) + "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "b-modal",
                rawName: "v-b-modal.modal-secret-request-access",
                modifiers: { "modal-secret-request-access": true },
              },
            ],
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$bvModal.hide("modal-secret-login")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t("site.ui.navigation.secret_sales.request_access")
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "b-modal",
                rawName: "v-b-modal.modal-secret-password-reset",
                modifiers: { "modal-secret-password-reset": true },
              },
            ],
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                return _vm.$bvModal.hide("modal-secret-login")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$t("site.ui.navigation.secret_sales.forgot_password")
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }