var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-share",
        "dialog-class": "modal-share",
        "modal-footer": "no",
        "hide-backdrop": _vm.hideBackdrop,
        "hide-footer": true,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("site.share_modal.headline"))),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("site.share_modal.sub_headline"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("modal-share")
                    },
                  },
                },
                [_c("i", { staticClass: "cli cli-times" })]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("site.share_modal.send_link")) + ":")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: `https://wa.me/?text=${_vm.url}`,
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "cli cli-whatsapp" })]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: `fb-messenger://share/?link=${_vm.url}`,
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "cli cli-messenger" })]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: _vm.smsLink, target: "_blank" } }, [
            _c("i", { staticClass: "cli cli-sms" }),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            { attrs: { href: `mailto:?subject=${_vm.url}`, target: "_blank" } },
            [_c("i", { staticClass: "cli cli-email-mobile" })]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "share" }, [
        _vm._v(_vm._s(_vm.$t("site.share_modal.share_page")) + ":"),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: `https://www.facebook.com/sharer/sharer.php?u=${_vm.url}`,
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "cli cli-facebook" })]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: `https://twitter.com/home?status=${_vm.url}`,
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "cli cli-twitter" })]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: `https://www.linkedin.com/sharing/share-offsite/?url=${_vm.url}`,
                target: "_blank",
              },
            },
            [_c("i", { staticClass: "cli cli-linkedin" })]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }