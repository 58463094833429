var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-navigation fixed-top",
      class: {
        "nav-dark": _vm.isDark,
        "nav-transparent": _vm.isTransparent,
        "is-fixed": _vm.isScrolled,
        "is--search-open": _vm.isSearchOpen,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }