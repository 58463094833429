var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-footer": "",
        "modal-class": "slide-in-modal",
        "hide-header-close": "",
      },
      on: { close: _vm.onClose },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c("p", { staticClass: "slide-in-modal__title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("property.slide_in.title")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "slide-in-modal__text" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("property.slide_in.text")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "my-4 is--active",
          attrs: { variant: "primary", href: _vm.contactUrl },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("property.slide_in.button_text")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "slide-in-modal__close d-block",
          attrs: { variant: "link" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onClose.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("property.slide_in.close")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("a"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }