var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bookmark-action-wrapper", on: { click: _vm.toggle } },
    [
      _vm.enabled
        ? _c("i", { staticClass: "bookmark-icon cli cli-heart" })
        : _c("i", { staticClass: "bookmark-icon cli cli-heart-outline" }),
      _vm._v(" "),
      _vm._t("text"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }