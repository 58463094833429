<template>
  <div class="tab-content">
    <div class="inner">
      <div class="title">
        <span>{{ $t('site.ui.property_filter.city') }}</span>
      </div>
      <div
        class="location-slider__control slider-prev"
        @click="slider.prev()"
      >
        <i class="cli-arrow-left-outline" />
      </div>
      <div
        ref="slider"
        class="location-slider keen-slider"
      >
        <div
          v-for="city in cityOptions"
          :key="city.id"
          class="item keen-slider__slide"
        >
          <div
            class="check"
            :class="{'checked': $store.state.filter.currentFilter.city.indexOf(city.id.toString()) > -1}"
            @click="$emit('toggle-city', city.id.toString())"
          />
          <div class="name">
            {{ city.name }}
          </div>
<!--          <span>{{ getPropertyCountForCity(city.id) }}</span>-->
<!--          {{ $t('site.ui.properties') }}-->
        </div>
      </div>
      <div
        class="location-slider__control slider-next"
        @click="slider.next()"
      >
        <i class="cli-arrow-right-outline" />
      </div>
    </div>
  </div>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

export default {
  props: {
    region: {
      type: Number,
      required: true
    },
    cityOptions: {
      type: Array,
      default: []
    },
    preparedConditions: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    slider: null,
    selected: [],
    regionChanged: false
  }),
  watch: {
    region(newVal, oldVal) {
      this.regionChanged = true
    },
    cityOptions(newVal, oldVal){
      if(this.regionChanged){
        this.initSlider();
      }
    },
  },
  mounted() {
    this.initSlider();
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
  methods: {
    initSlider() {
      let self = this;

      if (window.innerWidth > 992) {
        if (this.slider) {
          this.slider.destroy();
        }

        setTimeout(() => {
          self.slider = new KeenSlider(self.$refs.slider, {
            resetSlide: true,
            slidesPerView: 3
          });

          self.regionChanged = false

          self.$emit('slider-changed');
        }, 100);
      }
    },
    getPropertyCountForCity(cityId) {
      if (this.preparedConditions) {
        for (let condition of this.preparedConditions) {
          if (condition.fieldName === 'city') {
            for (let value of condition.values) {
              if (cityId === parseInt(value.value)) {
                return value.count;
              }
            }
          }
        }
      }

      return 0;
    }
  }
}
</script>