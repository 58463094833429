var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "newsletter-slide" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row d-flex justify-content-center" }, [
        _c("div", { staticClass: "col-xl-9" }, [
          _c(
            "div",
            {
              staticClass:
                "newsletter-slide__inner row d-flex align-items-center",
            },
            [
              _c("div", { staticClass: "col-md-6 col-xl-5" }, [
                _c("p", { staticClass: "newsletter-slide__title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("property.newsletter_slide.title")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "newsletter-slide__text" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("property.newsletter_slide.text")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 col-xl-7" }, [
                !_vm.request.finished
                  ? _c(
                      "div",
                      [
                        _c("ValidationObserver", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ handleSubmit }) {
                                  return [
                                    _c(
                                      "form",
                                      {
                                        attrs: { novalidate: "" },
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return handleSubmit(_vm.onSubmit)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkboxes" },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                staticClass:
                                                  "has--border-right",
                                                attrs: {
                                                  name: "check-button",
                                                  value: "mr",
                                                },
                                                model: {
                                                  value: _vm.inquiry.salutation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.inquiry,
                                                      "salutation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "inquiry.salutation",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "site.form.inquiry.salutation_mr"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  name: "check-button",
                                                  value: "ms",
                                                },
                                                model: {
                                                  value: _vm.inquiry.salutation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.inquiry,
                                                      "salutation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "inquiry.salutation",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "site.form.inquiry.salutation_ms"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-6 pr-md-1 mb-2",
                                            },
                                            [
                                              _c("ValidationProvider", {
                                                attrs: {
                                                  rules: "required",
                                                  name: _vm.$t(
                                                    "property.newsletter_slide.firstname_placeholder"
                                                  ),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("b-input", {
                                                            staticClass:
                                                              "email-input",
                                                            class: {
                                                              "has--errors":
                                                                errors.length >
                                                                0,
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "property.newsletter_slide.firstname_placeholder"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.inquiry
                                                                  .firstName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.inquiry,
                                                                    "firstName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "inquiry.firstName",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-6 pl-md-1 mb-2",
                                            },
                                            [
                                              _c("ValidationProvider", {
                                                attrs: {
                                                  rules: "required",
                                                  name: _vm.$t(
                                                    "property.newsletter_slide.lastname_placeholder"
                                                  ),
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("b-input", {
                                                            staticClass:
                                                              "email-input",
                                                            class: {
                                                              "has--errors":
                                                                errors.length >
                                                                0,
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "property.newsletter_slide.lastname_placeholder"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.inquiry
                                                                  .lastName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.inquiry,
                                                                    "lastName",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "inquiry.lastName",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: "required|email",
                                            name: _vm.$t(
                                              "property.newsletter_slide.email_placeholder"
                                            ),
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("b-input", {
                                                      staticClass:
                                                        "email-input",
                                                      class: {
                                                        "has--errors":
                                                          errors.length > 0,
                                                      },
                                                      attrs: {
                                                        type: "email",
                                                        placeholder: _vm.$t(
                                                          "property.newsletter_slide.email_placeholder"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.inquiry.email,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.inquiry,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "inquiry.email",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: {
                                              required: { allowFalse: false },
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass: "my-4",
                                                        class: {
                                                          "has--errors":
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "check-button",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.inquiry
                                                              .acceptance,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.inquiry,
                                                              "acceptance",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "inquiry.acceptance",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$t(
                                                                "property.newsletter_slide.acceptance"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "submit",
                                              variant: "secondary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "property.newsletter_slide.button"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3750246258
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.request.finished && _vm.request.success
                  ? _c("div", { staticClass: "form-success" }, [
                      _c("p", { staticClass: "newsletter-slide__title" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("property.newsletter_slide.success_title")
                            ) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "newsletter-slide__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("property.newsletter_slide.success_text")
                            ) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }