var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-carousel",
    {
      staticClass: "hero-slider",
      attrs: {
        id: _vm.id,
        fade: "",
        interval: 5000,
        indicators: _vm.indicators,
        background: "#ababab",
      },
      on: { "sliding-start": _vm.onSlideStart, "sliding-end": _vm.onSlideEnd },
    },
    [_vm._t("default"), _vm._v(" "), _c("b-carousel")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }