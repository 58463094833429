<template>
    <div class="native-sharer cursor-pointer" @click="share()">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        url: {
            type: String,
            default: window.location.href
        },
        title: String,
        text: String
    },
    methods: {
        share() {
            if (navigator.share) {
                navigator.share({
                    title: this.title,
                    text: this.text,
                    url: this.url
                })
            } else {
                this.$bvModal.show('modal-share');
            }
        }
    }
}
</script>