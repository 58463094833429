<template>
  <div class="search-property-form">
    <div class="form-group">
      <label
        for="search"
        class="d-none"
      >{{ $t('cli.form.property_search.placeholder') }}</label>
      <input
        id="search"
        v-model="query"
        type="text"
        class="form-control"
        :placeholder="$t('cli.form.property_search.placeholder')"
        @keydown.enter="onEnter()"
        @input="onSearch()"
      >
      <button
        class="submit"
        @click="$emit('enter')"
      >
        <i class="cli cli-search" />
      </button>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import queryString from 'query-string'

export default {
    props: {
        value: String,
        targetPath: String
    },
    data: () => ({
        query: null
    }),
    methods: {
        onEnter() {
            if (this.query) {
                window.location.href = this.targetPath + '?' + queryString.stringify({
                    query: this.query,
                    coming_from: 'search_dropdown'
                });
            }
        },
        onSearch() {
            this.$emit('input', this.query);
            this.emitSearchEvent(this, this.query);
        },
        emitSearchEvent: _.debounce((vm, query) => {
            vm.$emit('search', query);
        }, 300)
    }
}
</script>