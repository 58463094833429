import Vue from 'vue';
import {
    ModalPlugin,
    DropdownPlugin,
    CardPlugin,
    ButtonPlugin,
    NavbarPlugin,
    CarouselPlugin,
    ImagePlugin,
    TabsPlugin,
    FormPlugin,
    FormInputPlugin,
    FormTextareaPlugin,
    FormSelectPlugin,
    FormCheckboxPlugin,
    FormGroupPlugin,
    AlertPlugin,
    InputGroupPlugin
} from 'bootstrap-vue';

Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);
Vue.use(CardPlugin);
Vue.use(ButtonPlugin);
Vue.use(NavbarPlugin);
Vue.use(CarouselPlugin);
Vue.use(ImagePlugin);
Vue.use(TabsPlugin);
Vue.use(FormPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(AlertPlugin);
Vue.use(InputGroupPlugin);