<template>
    <div>
        <div class="property-item-slider__wrapper keen-slider" ref="propertyItemSlider" @mouseover="setPause(true)"
             @mouseout="setPause(false)">
            <slot name="items"></slot>
        </div>
        <ol v-if="propertyItemSlider" class="carousel-indicators">
            <li
                v-for="(slide, idx) in dotHelper"
                @click="propertyItemSlider.moveToSlideRelative(idx)"
                :class="{dot: true, active: current === idx}"
                :key="idx"
            ></li>
        </ol>
    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default {
    data: () => ({
        current: 0,
        pause: false,
        interval: null,
        propertyItemSlider: null
    }),
    computed: {
        dotHelper() {
            let mobile = window.matchMedia('(max-width: 768px)').matches;
            return this.propertyItemSlider ? [...Array(this.propertyItemSlider.details().size).keys()].filter((x, i) => i % (mobile ? 1 : 2) === 0) : [];
        }
    },
    methods: {
        setPause(active) {
            this.pause = active;
            this.setInterval();
        },
        resetInterval() {
            clearInterval(this.interval);
        },
        setInterval() {
            this.resetInterval();
            this.interval = setInterval(() => {
                if (!this.pause) {
                    this.propertyItemSlider.next();
                }
            }, 3000);
        }
    },
    mounted() {
        this.propertyItemSlider = new KeenSlider(this.$refs.propertyItemSlider, {
            initial: this.current,
            slidesPerView: 2,
            spacing: 20,
            dragStart: () => {
                this.setPause(true);
            },
            dragEnd: () => {
                this.setPause(false);
            },
            slideChanged: s => {
                this.current = s.details().relativeSlide;
            },
            breakpoints: {
                '(max-width: 768px)': {
                    slidesPerView: 1,
                    spacing: 0
                }
            }
        });

        this.setInterval();
    },
    beforeDestroy() {
        if (this.propertyItemSlider) this.propertyItemSlider.destroy();
    }
}
</script>