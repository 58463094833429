<template>
  <b-carousel
    :id="id"
    fade
    class="hero-slider"
    :interval="5000"
    :indicators="indicators"
    background="#ababab"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <slot />
    <b-carousel />
  </b-carousel>
</template>
<script>
export default {
    props: {
        interval: {
            type: Number,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        indicators: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        let videoElements = Array.from(document.getElementsByClassName('carousel-item__video'));
        const source = document.createElement('source');
        videoElements.forEach(element => {
            source.type = element.dataset.videoDesktopType;
            source.src = element.dataset.videoDesktop;
            if (this.isMobileDevice() && element.dataset.videoMobile !== '') {
                source.type = element.dataset.videoMobileType;
                source.src = element.dataset.videoMobile;
            }
            element.appendChild(source);
        });
    },
    methods: {
        onSlideStart(slide) {
            const el = document.getElementsByClassName('animation-container')[slide];

            el.classList.remove('aos-init')
            el.classList.remove('aos-animate')
        },
        onSlideEnd(slide) {
            window.AOS.init();
        },
        isMobileDevice() {
            return /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent);
        }
    }
}
</script>

