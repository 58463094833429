var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hero-image-slider position-relative",
      class: {
        "has--content-below-pagination": _vm.applyContentBelowPagination,
      },
    },
    [
      _c(
        "b-carousel",
        {
          ref: "slider",
          staticClass: "hero-slider",
          attrs: {
            id: "carousel-1",
            interval: 0,
            "no-hover-pause": true,
            controls: "",
            indicators: "",
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.index,
            callback: function ($$v) {
              _vm.index = $$v
            },
            expression: "index",
          },
        },
        [
          _vm._l(_vm.images, function (image) {
            return _c("b-carousel-slide", {
              key: image,
              scopedSlots: _vm._u(
                [
                  {
                    key: "img",
                    fn: function () {
                      return [
                        _c("b-img-lazy", {
                          staticClass: "w-100",
                          attrs: { src: image, block: "", fluid: "" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.hasPromoLastSlide
            ? _c(
                "b-carousel-slide",
                {
                  key: "promo-slide",
                  staticClass: "promo-slide",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "img",
                        fn: function () {
                          return [
                            _c("b-img-lazy", {
                              staticClass: "w-100",
                              attrs: {
                                src: _vm.lastImage,
                                block: "",
                                fluid: "",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2795507991
                  ),
                },
                [_vm._t("promo_slide")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.controls
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "hero-image-slider__progress position-absolute w-100",
              },
              [
                _c("div", {
                  staticClass: "hero-image-slider__progress-bar",
                  style: { width: _vm.percents + "%" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "hero-image-slider__button is-stop position-absolute text-center text-uppercase",
                on: {
                  click: function ($event) {
                    _vm.mode === "play" ? _vm.stop() : _vm.play()
                  },
                },
              },
              [
                _vm.mode === "play"
                  ? _c("i", { staticClass: "cli cli-break" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.mode === "stop"
                  ? _c("i", { staticClass: "cli cli-play-button" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "hero-image-slider__button-label d-block" },
                  [_vm._v("Player")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("text"),
      _vm._v(" "),
      _vm.applyContentBelowPagination ? _vm._t("below_pagination") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }