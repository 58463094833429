var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inner-form" },
    [
      _c(
        "b-alert",
        {
          attrs: {
            variant: "success",
            show: _vm.requestFinished && _vm.success,
          },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("site.ui.newsletter.success")) +
              "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-alert",
        {
          attrs: {
            variant: "danger",
            show: _vm.requestFinished && !_vm.success,
          },
        },
        [
          !_vm.active
            ? _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("site.ui.newsletter.error")) +
                    "\n            "
                ),
              ])
            : _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("site.ui.newsletterActive.error")) +
                    "\n            "
                ),
              ]),
        ]
      ),
      _vm._v(" "),
      _vm.success === false
        ? [
            _vm.captchaVisible
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("vue-recaptcha", {
                        attrs: { sitekey: _vm.siteKey },
                        on: {
                          verify: function ($event) {
                            return _vm.signUp()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c("ValidationObserver", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "form",
                                  {
                                    attrs: { novalidate: "" },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return handleSubmit(_vm.signUp)
                                      },
                                    },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _vm._l(
                                                  errors,
                                                  function (error) {
                                                    return _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "input-error",
                                                      },
                                                      [_vm._v(_vm._s(error))]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  staticClass: "name",
                                                  class: {
                                                    "has--errors":
                                                      errors.length > 0,
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "site.form.newsletter.first_name"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.subscriber.firstName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.subscriber,
                                                        "firstName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "subscriber.firstName",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _vm._l(
                                                  errors,
                                                  function (error) {
                                                    return _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "input-error",
                                                      },
                                                      [_vm._v(_vm._s(error))]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c("b-form-input", {
                                                  staticClass: "name",
                                                  class: {
                                                    "has--errors":
                                                      errors.length > 0,
                                                  },
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "site.form.newsletter.last_name"
                                                    ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.subscriber.lastName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.subscriber,
                                                        "lastName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "subscriber.lastName",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _vm.formType === "standard" ||
                                    _vm.formType === "combination"
                                      ? _c("ValidationProvider", {
                                          attrs: { rules: "required|email" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _vm._l(
                                                      errors,
                                                      function (error) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "input-error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(error)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c("b-form-input", {
                                                      staticClass: "email",
                                                      class: {
                                                        "has--errors":
                                                          errors.length > 0,
                                                      },
                                                      attrs: {
                                                        type: "email",
                                                        placeholder: _vm.$t(
                                                          "site.form.newsletter.email"
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.subscriber.email,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subscriber,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subscriber.email",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.formType === "whatsapp" ||
                                    _vm.formType === "combination"
                                      ? _c(
                                          "div",
                                          { staticClass: "news-container" },
                                          [
                                            _vm.formType === "combination"
                                              ? _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "bottom-check",
                                                    attrs: {
                                                      name: "checkbox-whatsapp-button",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.subscriber
                                                          .whatsappAcceptance,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.subscriber,
                                                          "whatsappAcceptance",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "subscriber.whatsappAcceptance",
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.$t(
                                                            "site.form.newsletter.whatsapp.acceptance"
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.subscriber.whatsappAcceptance
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "news-container--phone",
                                                  },
                                                  [
                                                    _c("ValidationProvider", {
                                                      attrs: {
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _vm._l(
                                                                  errors,
                                                                  function (
                                                                    error
                                                                  ) {
                                                                    return _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "input-error",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            error
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "VuePhoneNumberInput",
                                                                  {
                                                                    class: {
                                                                      "has--errors":
                                                                        errors.length >
                                                                        0,
                                                                    },
                                                                    attrs: {
                                                                      translations:
                                                                        _vm.phoneInputTranslations,
                                                                      "default-country-code":
                                                                        "DE",
                                                                      "only-countries":
                                                                        _vm.onlyCountries,
                                                                    },
                                                                    on: {
                                                                      update:
                                                                        _vm.savePhoneMetadata,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .subscriber
                                                                          .phone,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.subscriber,
                                                                            "phone",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "subscriber.phone",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "news-container" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            rules: {
                                              required: { allowFalse: false },
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _vm._l(
                                                      errors,
                                                      function (error) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "input-error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(error)
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        staticClass:
                                                          "bottom-check",
                                                        class: {
                                                          "has--errors":
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "check-button",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.subscriber
                                                              .acceptance,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.subscriber,
                                                              "acceptance",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "subscriber.acceptance",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$t(
                                                                "site.form.newsletter.acceptance"
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "submit",
                                            attrs: {
                                              type: "submit",
                                              variant: "secondary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "site.form.newsletter.submit"
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3534746199
                      ),
                    }),
                  ],
                  1
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }